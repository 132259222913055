export function validateNameLength(name) {
    if (name.length > 100) {
        return false;
    } else {
        return true;
    }
}
export function validateEmail(userEmail) {
    var mailformat =
        /^(([^<>()\[\]\\.,;!:\s@"]+(\.[^<>()\[\]\\.,;:!\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let errorMsg = "";
    let mailToValidate = userEmail.trim();
    if (mailToValidate === "") {
        errorMsg = "This field should not be empty";
    } else if (!userEmail.trim().match(mailformat)) {
        errorMsg = "Please enter valid email";
    }
    return errorMsg !== "" ? errorMsg : true;
}

export const validateName = (name) => {
    let errorMsg = "";
    let textToValid = name.trim();

    if (textToValid === "") {
        errorMsg = "This field should not be empty";
    } else if (!validateNameLength(textToValid)) {
        errorMsg = "Maximum 100 characters allowed";
    }
    return errorMsg !== "" ? errorMsg : true;
};
export const validateUserName = (name) => {
    let errorMsg = "";
    let textToValid = name.trim();
    let pattern = /[^a-zA-Z0-9\-_ ]/;

    if (textToValid === "") {
        errorMsg = "This field should not be empty";
    } else if (!validateNameLength(textToValid)) {
        errorMsg = "Maximum 100 characters allowed";
    } else if (pattern.test(name)) {
        errorMsg = "Special characters not allowed.";
    }
    return errorMsg !== "" ? errorMsg : true;
};
export const validateWorkspaceUserName = (name) => {
    let errorMsg = "";
    let slash = /[/\\\\]/;
    let textToValid = name.trim();
    let pattern = /[^a-zA-Z0-9\-_ ]/;
    if (textToValid === "") {
        errorMsg = "This field should not be empty";
    } else if (!validateNameLength(textToValid)) {
        errorMsg = "Maximum 100 characters allowed";
    } else if (pattern.test(name.charAt(0))) {
        errorMsg = "Special characters not allowed.";
    } else if (slash.test(name)) {
        errorMsg = "Slash characters not allowed.";
    }
    return errorMsg !== "" ? errorMsg : true;
};
export const validateStepperName = (name) => {
    let errorMsg = "";
    let textToValid = name.trim();

    if (textToValid === "") {
        errorMsg = "This field should not be empty";
    } else if (!validateNameLength(textToValid)) {
        errorMsg = "Maximum 100 characters allowed";
    } else if (!name) {
        errorMsg = "cannot be empty";
    }
    return errorMsg !== "" ? errorMsg : true;
};
export const validatePasswordPatern = (password) => {
    let passwordToValidate = password.trim();
    let errorMsg = "";
    var password_Pattern =
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,256}$/;
    if (!passwordToValidate.match(password_Pattern)) {
        errorMsg =
            "Wrong password format. Try with above 8 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character";
    }
    return errorMsg !== "" ? errorMsg : true;
};
