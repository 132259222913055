import React, { useEffect, useState } from "react";
import { makeStyles, Paper } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Timeline from "@material-ui/lab/Timeline";
import { Menu, MenuItem } from "@mui/material";
import CustomSnackbar from "../../../../stylized/CustomSnackbar/CustomSnackbar";
import { useDispatch, useSelector } from "react-redux";
import { fetchFilterData } from "../../../../../actions/DashboardActions";
import http from "../../../../../bae-client/HTTP";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import Loader from "../../../../stylized/Loader";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import AddInstance from "../document-chain/modals/AddInstance";
import "./verticalTimeline.css";
import AddDocuments from "../document-chain/modals/AddDocuments";
import { setSelectedFlows } from "../../../../../actions/DocumentChainActions";
import AlertDialog from "../../../../stylized/AlertDialog";
function VerticalTimelineView(props) {
    const [alertDialogMessage, setAlertDialogMessage] = useState("");
    const [selectedRow, setSelectedRow] = React.useState(null);
    const [showSnackbar, setShowSnackbar] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState("");
    const [selectedGenericInstances, setSelectedGenericInstances] = useState(
        []
    );
    const [selectedInstance, setSelectedInstance] = React.useState(null);
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [showAddInstanceModal, setShowAddInstanceModal] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [alertDialogShow, setAlertDialogShow] = useState(false);
    const [alertDialogContent, setAlertDialogContent] = useState({
        title: "",
        message: "",
        isAlert: false,
    });
    const [showDocumentModal, setShowDocumentModal] = React.useState(false);
    const [folderId, setFolderId] = React.useState(0);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [values, setValues] = React.useState(null);
    const dispatch = useDispatch();
    const open = Boolean(anchorEl);
    const handleClickOpt = (event, row) => {
        setAnchorEl(event.currentTarget);
    };
    const fetchLatestFlowsData = () => {
        http.sendRestRequest("documentChain/objects")
            .then((response) => {
                dispatch(fetchFilterData(response));
            })
            .catch((err) => console.log(err));
    };
    useEffect(() => {
        fetchLatestFlowsData();
        setShowLoader(true);
        http.sendRestRequest("documentChain/generic/list")
            .then((flows) => {
                setShowLoader(false);
                const flowNames = flows.map((flow) => flow.genericDisplayName);
                dispatch(setSelectedFlows(flowNames));
            })
            .catch((error) => {
                setShowLoader(false);
                console.log(error);
            });
    }, []);
    const changeOpenDialogFlag = (value) => {
        setShowAlertDialog(value);
        setAlertDialogMessage("");
    };
    const getFoldersId = (item) => {
        setValues({
            workSpaceId: item.workspaceId,
            spaceId: item.spaceId,
            documentId: item.documentId,
        });

        let url = `spacePersistence/folderId?spaceId=${item.spaceId}&documentId=${item.documentId}`;
        http.sendRestRequest(url, "GET")
            .then((resp) => {
                setValues({
                    workSpaceId: item.workspaceId,
                    spaceId: item.spaceId,
                    documentId: item.documentId,
                });
                setFolderId(resp.folderId);
            })
            .catch((err) => {
                console.error(err);
            });
    };
    const showAlertView = (title, body) => {
        setAlertDialogContent({ title: title, message: body });
        setAlertDialogShow(true);
    };
    const handleClose = (e) => {
        setAnchorEl(null);
    };
    let value = 0;
    const closeModal = () => {
        handleClose();
        setShowDocumentModal(false);
    };
    const closeInstance = () => {
        setShowAddInstanceModal(false);
        setShowDocumentModal(true);
    };
    const openAddInstanceModal = () => {
        setShowAddInstanceModal(true);
        // setShowDocumentModal(false);
    };

    const useStyles = makeStyles({
        timelineContent: {
            display: "inline-block",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            cursor: "pointer",
            wordBreak: "break-word",
        },
        timelineDate: {
            fontSize: "0.7rem",
            fontFamily: "sans-serif",
            fontWeight: "600",
            lineHeight: "1rem",
            letterSpacing: "0.00938em",
            height: "100%",
            width: "100%",
        },
        timelineDateWrapper: {
            height: "2.5rem",
            width: "4rem",
            textAlign: "right",
            position: "relative",
            top: "0rem",
        },
        titleText: {
            fontSize: "0.7rem",
            fontFamily: "sans-serif",
            fontWeight: "600",
            lineHeight: "1rem",
            letterSpacing: "0.00938em",
            userSelect: "none",
        },
    });

    const openDocumentModal = (e) => {
        handleClose();
        setShowDocumentModal(true);
    };
    const handleClick = (id, e) => {
        props.setSelectedIndex(
            timelineDate.findIndex((obj) => obj.documentId === id)
        );
        props.getRippleValue(e);
    };

    const handleNodeClick = (documentId) => {
        props.onNodeClick(documentId);
    };
    const classes = useStyles();
    const timelineDate = props.documentList;
    const addNewInstance = (name, tags, branchId, branchName) => {
        setShowLoader(true);
        setShowAddInstanceModal(false);
        let encodedFamilyTags = encodeURIComponent(tags);
        http.sendRestRequest(
            `documentChain/instance?genericId=${
                selectedRow.id
            }&instanceName=${encodeURIComponent(
                name
            )}&familyTags=${encodedFamilyTags}&branchId=${branchId}&branchName=${branchName}`,
            "POST"
        )
            .then(() => {
                setSnackbarMessage("Instance created successfully!");
                setShowSnackbar(true);
                http.sendRestRequest(
                    `documentChain/instance/list?genericId=${selectedRow.id}`
                )
                    .then((instanceData) => {
                        setShowLoader(false);
                        const prevInstances = [...selectedGenericInstances].map(
                            (instance) => instance.instanceId
                        );
                        setSelectedGenericInstances(instanceData);
                        const newInstance = instanceData.filter(
                            (instance) =>
                                !prevInstances.includes(instance.instanceId)
                        );
                        setSelectedInstance(newInstance[0]);
                        setShowDocumentModal(true);
                    })
                    .catch((error) => {
                        setShowLoader(false);
                        setShowAlertDialog(true);
                        setAlertDialogMessage(error.error);
                    });
                fetchLatestFlowsData();
            })
            .catch((error) => {
                setShowLoader(false);
                setShowAlertDialog(true);
                setAlertDialogMessage(error.error);
            });
    };
    return (
        <>
            <div
                className="timeline_view_container"
                style={{ marginBottom: "10px" }}
            >
                <Timeline align="alternate" className="p-0">
                    {timelineDate.map((item, idx) => {
                        let classname =
                            props.selectedIndex === idx ||
                            props.selectedNode === item.document
                                ? "rippleLogRowOutline "
                                : "";
                        let tooltipText = !props.rippleTimeline
                            ? (item.genericStepName || "") +
                              "\n" +
                              (item.document || "Undefined") +
                              "\n" +
                              ("From : " + item.from || "") +
                              "\n" +
                              ("To : " + item.to || "")
                            : item.document || "Undefined";
                        return (
                            <TimelineItem key={idx}>
                                <TimelineOppositeContent
                                    className={classes.timelineDateWrapper}
                                >
                                    <Typography
                                        className={classes.timelineDate}
                                    >
                                        {item.date}
                                    </Typography>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot color="primary" />
                                    {timelineDate.length - 1 !== idx && (
                                        <TimelineConnector
                                            style={{ minHeight: "118px" }}
                                        />
                                    )}
                                    {/* <TimelineConnector />
                                {timelineDate.length - 1 === idx && (
                                    <TimelineDot color="primary" />
                                )} */}
                                </TimelineSeparator>

                                <TimelineContent>
                                    <Paper
                                        title={tooltipText}
                                        className={
                                            "doCenter timeline_paper " +
                                            classname
                                        }
                                        variant={"elevation"}
                                        elevation={5}
                                        id={`timeline_${item.document}`}
                                        onClick={() =>
                                            handleClick(
                                                item.documentId,
                                                item.document
                                            )
                                        }
                                        onDoubleClick={() =>
                                            handleNodeClick(item.documentId)
                                        }
                                    >
                                        <Typography
                                            className={classes.titleText}
                                            noWrap={true}
                                        >
                                            {item.genericStepName !== ""
                                                ? item.genericStepName
                                                : ""}
                                        </Typography>
                                        <Typography
                                            className={classes.titleText}
                                            noWrap={true}
                                            id={"div_document_item_" + idx}
                                        >
                                            {!props.rippleTimeline
                                                ? item.document !== ""
                                                    ? `${idx + 1}. ${
                                                          item.document
                                                      }`
                                                    : "Undefined Name"
                                                : item.document !== ""
                                                ? `${item.document}`
                                                : "Undefined Name"}
                                        </Typography>
                                        <div
                                            style={{ paddingLeft: "5px" }}
                                            onClick={(event) => {
                                                handleClickOpt(event);
                                                getFoldersId(item);
                                            }}
                                        >
                                            <img
                                                src="./images/options.png"
                                                alt="Option"
                                            />
                                        </div>
                                    </Paper>
                                </TimelineContent>
                            </TimelineItem>
                        );
                    })}
                </Timeline>
                <Menu
                    id="demo-customized-menu"
                    MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    style={{
                        boxShadow: "1px 1px 20px 4px rgba(0, 0, 0, 0.1)",
                        marginTop: "10px",
                    }}
                >
                    <MenuItem onClick={handleClose} id="upload" key="upload">
                        <div
                            id="upload_from_system"
                            style={{ paddingLeft: "4px" }}
                            onClick={openDocumentModal}
                        >
                            {/* <img
                                className="renameIcon"
                                src="./images/flow-icon-bw.svg"
                                alt="Attach to flow"
                                title="Attach to flow"
                            /> */}
                            <span className="btn_title" title="Upload">
                                Attach to flow
                            </span>
                        </div>
                    </MenuItem>
                </Menu>
            </div>
            {showDocumentModal ? (
                <AddDocuments
                    documentId={values.documentId}
                    setSelectedRow={setSelectedRow}
                    openAddInstanceModal={openAddInstanceModal}
                    values={values}
                    showAlertView={showAlertView}
                    folderId={folderId}
                    showDocumentModal={showDocumentModal}
                    onclick={() => {}}
                    setAlertDialogShow={setAlertDialogShow}
                />
            ) : null}
            <Loader show={showLoader}></Loader>
            <AlertDialog
                open={alertDialogShow}
                setOpen={setAlertDialogShow}
                title={alertDialogContent.title}
                message={alertDialogContent.message}
                isAlert={alertDialogContent.isAlert}
            ></AlertDialog>
            {showAddInstanceModal ? (
                <AddInstance
                    selectedGenericInstances={selectedGenericInstances}
                    selectedInstance={selectedInstance}
                    showAddInstanceModal={showAddInstanceModal}
                    closeModal={closeInstance}
                    currentFamilyTags={[]}
                    currentInstanceName={""}
                    heading={"Add New Instance"}
                    branchsId={"1"}
                    branchsName={"1"}
                    disabled={false}
                    editNewInstance={""}
                    addNewInstance={addNewInstance}
                ></AddInstance>
            ) : null}
            <CustomSnackbar
                open={showSnackbar}
                setOpen={setShowSnackbar}
                alertMessage={snackbarMessage}
            ></CustomSnackbar>
            <AlertDialog
                open={showAlertDialog}
                message={alertDialogMessage}
                title={""}
                isAlert={false}
                setOpen={changeOpenDialogFlag}
            ></AlertDialog>
        </>
    );
}

export default VerticalTimelineView;
