import { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
Chart.register(CategoryScale);

const numberToMonthsMap = new Map([
    [1, "JAN"],
    [2, "FEB"],
    [3, "MAR"],
    [4, "APR"],
    [5, "MAY"],
    [6, "JUN"],
    [7, "JUL"],
    [8, "AUG"],
    [9, "SEP"],
    [10, "OCT"],
    [11, "NOV"],
    [12, "DEC"],
]);
const numberToWeekMap = new Map([
    [1, "WK1"],
    [2, "WK2"],
    [3, "WK3"],
    [4, "WK4"],
    [5, "WK5"],
    [6, "WK6"],
    [7, "WK7"],
]);
const numberToQuarterMap = new Map([
    [1, "Q1"],
    [2, "Q2"],
    [3, "Q3"],
    [4, "Q4"],
]);
const getQuarters = new Map([
    [0, "Q1"],
    [1, "Q1"],
    [2, "Q1"],
    [3, "Q2"],
    [4, "Q2"],
    [5, "Q2"],
    [6, "Q3"],
    [7, "Q3"],
    [8, "Q3"],
    [9, "Q4"],
    [10, "Q4"],
    [11, "Q4"],
]);
function getNumberOfWeek(date) {
    let adjustedDate = date.getDate() + date.getDay();
    let prefixes = ["0", "1", "2", "3", "4", "5"];
    return parseInt(prefixes[0 | (adjustedDate / 7)]) + 1;
}
const monthsBtwnDates = (startDate, endDate) => {
    return Math.max(
        (endDate.getFullYear() - startDate.getFullYear()) * 12 +
            endDate.getMonth() -
            startDate.getMonth(),
        0
    );
};
const getWeekOfMonth = (d) => {
    let date = d.getDate();
    let day = d.getDay();
    return Math.ceil((date - 1 - day) / 7);
};

const getDaysOfMonth = (d) => {
    const date = d.getDate(d).toString();
    const month = numberToMonthsMap.get(d.getMonth() + 1);
    const len = date.length;
    if (date[len - 1] === "1") return date + "st " + month;
    if (date[len - 1] === "2") return date + "nd " + month;
    if (date[len - 1] === "3") return date + "rd " + month;
    return date + "th " + month;
};

const getHours = (d) => {
    return d.getHours();
};

let measure = "hrs";
const getChartData = (data, type, startDate, endDate, filterTwo) => {
    if (type === "Performance") {
        if (data && data.length) {
            const stepNames = data.map((step) => {
                // var matches = step.genericStepName.match(/\b(\w)/g);
                // var acronym = matches.join("");
                // return acronym.toUpperCase();
                return step.genericStepName;
            });
            let expectedTime = data.map((step) =>
                Math.floor(step.expectedDuration / 60)
            );
            let averageTime = data.map((step) =>
                Math.floor(step.averageDuration / 60)
            );
            let timeMeasureExpectedTime = [];
            let timeMeasureAverageTime = [];
            measure = "hrs";
            timeMeasureExpectedTime = expectedTime.filter((time) => time > 240);
            timeMeasureAverageTime = averageTime.filter((time) => time > 240);
            if (
                timeMeasureExpectedTime.length ||
                timeMeasureAverageTime.length
            ) {
                measure = "days";
                expectedTime = expectedTime.map((time) => time / 24);
                averageTime = averageTime.map((time) => time / 24);
            }
            timeMeasureExpectedTime = expectedTime.filter((time) => time > 720);
            timeMeasureAverageTime = averageTime.filter((time) => time > 720);
            if (
                timeMeasureExpectedTime.length ||
                timeMeasureAverageTime.length
            ) {
                measure = "wks";
                expectedTime = expectedTime.map((time) => time / (24 * 7));
                averageTime = averageTime.map((time) => time / (24 * 7));
            }
            timeMeasureExpectedTime = expectedTime.filter(
                (time) => time > 1440
            );
            timeMeasureAverageTime = averageTime.filter((time) => time > 1440);
            if (
                timeMeasureExpectedTime.length ||
                timeMeasureAverageTime.length
            ) {
                measure = "months";
                expectedTime = expectedTime.map((time) => time / (24 * 60));
                averageTime = averageTime.map((time) => time / (24 * 60));
            }
            return {
                labels: stepNames,
                datasets: [
                    {
                        label: "Average Time Overall",
                        fill: false,
                        lineTension: 0.5,
                        backgroundColor: filterTwo ? "red" : "green",
                        borderColor: filterTwo ? "red" : "green",
                        borderWidth: 2,
                        data: averageTime,
                    },
                    {
                        label: "Time Expected",
                        fill: false,
                        lineTension: 0.5,
                        backgroundColor: filterTwo ? "yellow" : "#27AAE1",
                        borderColor: filterTwo ? "yellow" : "#27AAE1",
                        borderWidth: 2,
                        data: expectedTime,
                    },
                ],
            };
        }
    }
    if (type === "Spike") {
        let weeks = [];
        let labels = [];
        const startTime = startDate
            ? new Date(startDate).getTime()
            : new Date(data[0].date).getTime();
        const endTime = endDate
            ? new Date(endDate).getTime()
            : new Date(data[data.length - 1].date).getTime();
        const date = startDate ? new Date(startDate) : new Date(data[0].date);
        const endDateObject = endDate
            ? new Date(endDate)
            : new Date(data[data.length - 1].date);

        if (endTime - startTime < 2592000000) {
            for (let index = startTime; index <= endTime; ) {
                let d = new Date(index);
                let day = getDaysOfMonth(d);
                if (!labels.includes(day)) {
                    labels.push(day);
                    weeks.push(0);
                }
                index = index + 86400000;
            }
            data.forEach((element) => {
                let d = new Date(element.date);
                let week = getDaysOfMonth(d);
                let monthIndex = labels.indexOf(week);
                weeks[monthIndex] = weeks[monthIndex] + 1;
            });
        } else if (endTime - startTime < 5259487667) {
            for (let index = startTime; index <= endTime; ) {
                let d = new Date(index);
                let weekNumber = getWeekOfMonth(d);
                if (
                    !labels.includes(
                        numberToWeekMap.get(weekNumber + 1) +
                            "-" +
                            numberToMonthsMap.get(d.getMonth() + 1)
                    )
                ) {
                    labels.push(
                        numberToWeekMap.get(weekNumber + 1) +
                            "-" +
                            numberToMonthsMap.get(d.getMonth() + 1)
                    );
                    weeks.push(0);
                }
                index = index + 86400000;
            }
            data.forEach((element) => {
                let d = new Date(element.date);
                let week = getWeekOfMonth(d) + 1;
                let monthIndex = labels.indexOf(
                    numberToWeekMap.get(week) +
                        "-" +
                        numberToMonthsMap.get(d.getMonth() + 1)
                );
                weeks[monthIndex] = weeks[monthIndex] + 1;
            });
        } else if (endTime - startTime < 15778463000) {
            let startTimeRangeMonth = date.getMonth();
            let startTimeRangeYear = date.getFullYear();
            let monthCounter = startTimeRangeMonth;
            let yearCounter = startTimeRangeYear;
            const len = monthsBtwnDates(date, endDateObject);
            for (let index = 0; index < len + 1; index++) {
                weeks.push(0);
                labels.push(
                    numberToMonthsMap.get(monthCounter + 1) + "-" + yearCounter
                );
                if (monthCounter === 11) {
                    monthCounter = 0;
                    yearCounter++;
                } else {
                    monthCounter++;
                }
            }
            data.forEach((element) => {
                let d = new Date(element.date);
                let monthIndex = labels.indexOf(
                    numberToMonthsMap.get(d.getMonth() + 1) +
                        "-" +
                        d.getFullYear()
                );
                weeks[monthIndex] = weeks[monthIndex] + 1;
            });
        } else if (endTime - startTime < 63113852000) {
            let startTimeRangeMonth = date.getMonth();
            let startTimeRangeYear = date.getFullYear();
            const len = monthsBtwnDates(date, endDateObject);
            let monthCounter = startTimeRangeMonth;
            let yearCounter = startTimeRangeYear;
            for (let index = 0; index < len + 1; index++) {
                if (
                    !labels.includes(
                        getQuarters.get(monthCounter) + "-" + yearCounter
                    )
                ) {
                    labels.push(
                        getQuarters.get(monthCounter) + "-" + yearCounter
                    );
                    weeks.push(0);
                }
                if (monthCounter === 11) {
                    monthCounter = 0;
                    yearCounter++;
                } else monthCounter++;
            }

            data.forEach((element) => {
                let d = new Date(element.date);
                let monthIndex = labels.indexOf(
                    getQuarters.get(d.getMonth()) + "-" + d.getFullYear()
                );
                weeks[monthIndex] = weeks[monthIndex] + 1;
            });
        } else {
            let startTimeRangeYear = date.getFullYear();
            let endTimeRangeYear = endDateObject.getFullYear();
            for (
                let index = startTimeRangeYear;
                index < endTimeRangeYear + 1;
                index++
            ) {
                weeks.push(0);
                labels.push(index);
            }
            data.forEach((element) => {
                let d = new Date(element.date);
                let monthIndex = labels.indexOf(d.getFullYear());
                weeks[monthIndex] = weeks[monthIndex] + 1;
            });
        }
        return {
            labels: labels,
            datasets: [
                {
                    label: "No. of Docs",
                    fill: false,
                    lineTension: 0.5,
                    backgroundColor: filterTwo ? "green" : "#27AAE1",
                    borderColor: filterTwo ? "green" : "#27AAE1",
                    borderWidth: 2,
                    data: weeks,
                },
            ],
        };
    }
};

const LineChart = ({ data, type, filterData, filterData2, data2 }) => {
    const [chartState, setChartState] = useState(null);
    const [actualLabels, setActualLabels] = useState([]);
    useEffect(() => {
        if (data.length && filterData) {
            let filterOneCharData = null;
            setActualLabels(data.map((step) => step.genericStepName));
            if (filterData.selectedTimeType === "Last 90 days") {
                let endDate = new Date().toDateString();
                let startDate = new Date(
                    new Date(endDate).getTime() - 7776000000
                ).toDateString();
                filterOneCharData = getChartData(
                    data,
                    type,
                    startDate,
                    endDate
                );
            } else if (
                filterData.selectedTimeType === "Last 30 days" ||
                filterData.selectedTimeType === "Previous month"
            ) {
                let endDate = new Date().toDateString();
                let startDate = new Date(
                    new Date(endDate).getTime() - 2592000000
                ).toDateString();
                filterOneCharData = getChartData(
                    data,
                    type,
                    startDate,
                    endDate
                );
            } else if (filterData.selectedTimeType === "Last 14 days") {
                let endDate = new Date().toDateString();
                let startDate = new Date(
                    new Date(endDate).getTime() - 1209600000
                ).toDateString();
                filterOneCharData = getChartData(
                    data,
                    type,
                    startDate,
                    endDate
                );
            } else if (filterData.selectedTimeType === "Last 7 days") {
                let endDate = new Date().toDateString();
                let startDate = new Date(
                    new Date(endDate).getTime() - 604800000
                ).toDateString();
                filterOneCharData = getChartData(
                    data,
                    type,
                    startDate,
                    endDate
                );
            } else {
                filterOneCharData = getChartData(
                    data,
                    type,
                    filterData.startDate,
                    filterData.endDate
                );
            }
            let filterTwoCharData = null;
            if (filterData2 && filterData2.totalAlerts !== undefined) {
                const aLabels = filterData2.genericElements
                    .filter(function (el) {
                        return el.genericStepName !== "Email";
                    })
                    .map((step) => step.genericStepName);
                setActualLabels([...actualLabels, ...aLabels]);
                if (filterData2.selectedTimeType === "Last 90 days") {
                    let endDate = new Date().toDateString();
                    let startDate = new Date(
                        new Date(endDate).getTime() - 7776000000
                    ).toDateString();
                    filterTwoCharData = getChartData(
                        data2,
                        type,
                        startDate,
                        endDate,
                        true
                    );
                } else if (
                    filterData2.selectedTimeType === "Last 30 days" ||
                    filterData2.selectedTimeType === "Previous month"
                ) {
                    let endDate = new Date().toDateString();
                    let startDate = new Date(
                        new Date(endDate).getTime() - 2592000000
                    ).toDateString();
                    filterTwoCharData = getChartData(
                        data2,
                        type,
                        startDate,
                        endDate,
                        true
                    );
                } else if (filterData2.selectedTimeType === "Last 14 days") {
                    let endDate = new Date().toDateString();
                    let startDate = new Date(
                        new Date(endDate).getTime() - 1209600000
                    ).toDateString();
                    filterTwoCharData = getChartData(
                        data2,
                        type,
                        startDate,
                        endDate,
                        true
                    );
                } else if (filterData2.selectedTimeType === "Last 7 days") {
                    let endDate = new Date().toDateString();
                    let startDate = new Date(
                        new Date(endDate).getTime() - 604800000
                    ).toDateString();
                    filterTwoCharData = getChartData(
                        data2,
                        type,
                        startDate,
                        endDate,
                        true
                    );
                } else {
                    filterTwoCharData = getChartData(
                        data2,
                        type,
                        filterData2.startDate,
                        filterData2.endDate,
                        true
                    );
                }
            }
            if (filterTwoCharData) {
                const combineArray = [
                    ...filterOneCharData.labels,
                    ...filterTwoCharData.labels,
                ];
                const labels = combineArray.filter(
                    (item, index) => combineArray.indexOf(item) === index
                );
                const datasets1 = filterOneCharData.datasets.map((item) => {
                    let data = labels.map((l1, i) =>
                        item.data[i] ? item.data[i] : 0
                    );
                    return { ...item, data: data };
                });
                const datasets2 = filterTwoCharData.datasets.map((item) => {
                    let data = labels.map((l1, i) =>
                        item.data[i] ? item.data[i] : 0
                    );
                    return { ...item, data: data };
                });
                if (datasets1[0].label === "Average Time Overall") {
                    datasets1[0].label = "Avg. time Option1";
                    datasets1[1].label = "Time expected Option1";
                    datasets2[0].label = "Avg. time Option2";
                    datasets2[1].label = "Time expected Option2";
                } else {
                    datasets1[0].label = "No. of docs option 1";
                    datasets2[0].label = "No. of docs option 2";
                }

                setChartState({
                    labels: labels,
                    datasets: [...datasets1, ...datasets2],
                });
            } else setChartState(filterOneCharData);
        }
    }, [data, filterData, filterData2]);

    return (
        <div>
            {data && data.length && chartState ? (
                <Line
                    data={chartState}
                    options={{
                        plugins: {
                            title: {
                                display: true,
                                align: "center",
                                text: `${type} Analysis`,
                                fontSize: 20,
                                position: "bottom",
                            },
                            legend: {
                                display: true,
                                position: "top",
                            },
                            tooltip: {
                                enabled: true,
                                callbacks: {
                                    label: function (context) {
                                        return `${context.dataset.label}: ${context.formattedValue}`;
                                    },
                                    title: function (context) {
                                        if (type === "Performance")
                                            return actualLabels[
                                                context[0].dataIndex
                                            ];
                                        else return context[0].label;
                                    },
                                },
                            },
                        },
                        scales: {
                            x: {
                                ticks: {
                                    maxRotation: 90,
                                    minRotation: 45,
                                    callback: function (label) {
                                        let labelTxt =
                                            this.getLabelForValue(label)
                                                .length > 10
                                                ? this.getLabelForValue(
                                                      label
                                                  ).substring(0, 10) + "..."
                                                : this.getLabelForValue(label);
                                        return labelTxt;
                                    },
                                },
                            },
                            y: {
                                ticks: {
                                    callback: (value, index, ticks) => {
                                        if (type === "Performance")
                                            return value + " " + measure;
                                        else return value;
                                    },
                                },
                                beginAtZero: true,
                                type: "linear",
                                grace: "6",
                            },
                        },
                    }}
                />
            ) : null}
        </div>
    );
};

export default LineChart;
