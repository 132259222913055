import React from "react";
import classNames from "classnames";
import "./inputs.css";
import errorIcon from "./img/error.svg";
import successIcon from "./img/success.svg";
import disableSearche from "./img/disable_srch.svg";

const Inputs = ({
    type,
    placeholder,
    id,
    disable,
    isError,
    onChange,
    value,
    icon,
}) => {
    return (
        <div
            className={classNames("form-controls", {
                error: isError === true,
                success: isError === false,
            })}
        >
            <div className="input-container">
                <input
                    type={type}
                    id={id}
                    placeholder={placeholder}
                    disabled={disable}
                    onChange={onChange}
                    value={value}
                />
                {icon ? (
                    <img
                        src={disable ? disableSearche : icon}
                        alt="Search Icon"
                        className="icon"
                    />
                ) : isError === null ? null : (
                    <img
                        src={isError ? errorIcon : successIcon}
                        alt={isError ? "Error Icon" : "Success Icon"}
                        className="icon"
                    />
                )}
            </div>
        </div>
    );
};

export default Inputs;
