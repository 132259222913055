import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";

function DialogView({
    show,
    style,
    showTitle,
    dialogTitle,
    showFooter,
    size,
    children,
    handleClose,
    onBackdropClick,
    id
}) {
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState("sm");

    const useStyles = makeStyles((theme) => ({
        form: {
            display: "flex",
            flexDirection: "column",
            margin: "auto",
            width: "fit-content",
        },
        formControl: {
            marginTop: theme.spacing(2),
            minWidth: 120,
        },
        formControlLabel: {
            marginTop: theme.spacing(1),
        },
    }));
    // const handleFullWidthChange = (event) => {
    //     setFullWidth(event.target.checked);
    // };
    // const handleMaxWidthChange = (event) => {
    //     setMaxWidth(event.target.value);
    // };
    const classes = useStyles();
    const renderDialogContent = () => {
        return <DialogContent>{children}</DialogContent>;
    };
    return (
        <Dialog
            //fullWidth={'lg'}
            maxWidth={size ? size : "lg"}
            open={show}
            onClose={handleClose}
            onBackdropClick={
                onBackdropClick !== undefined ? onBackdropClick : true
            }
            id={id ? id : null}
            style={style}
        >
            {showTitle ? (
                <DialogTitle id="max-width-dialog-title">
                    {dialogTitle}
                </DialogTitle>
            ) : null}
            {children ? renderDialogContent() : null}
            {}
            {showFooter ? (
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            ) : null}
        </Dialog>
    );
}
export default DialogView;
