import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const options = [{
    action: "Open Instance",
    src: "./images/open.png",
    title: "Open"

}, {
    action: "Delete Instance",
    src: "./images/delete.svg",
    title: "Delete"

}];


export default function OptionMenu({ selectedRow, instanceOperations }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (operation) => {
        setAnchorEl(null);
        if (operation === "Open Instance" || operation === "Delete Instance")
            instanceOperations(operation, selectedRow);
    };

    return (
        <div>
            <div
                aria-label="more"
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                style={{ cursor: "pointer" }}
                title="options"
            >
                <MoreHorizIcon />
            </div>
            <Menu
                id="basic-menu"
                MenuListProps={{
                    "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            // PaperProps={{
            //     style: {
            //         maxHeight: ITEM_HEIGHT * 4.5,
            //         width: "20ch",
            //     },
            // }}
            >
                {options.map((option) => (
                    <MenuItem
                        key={option}
                        selected={option === "Pyxis"}
                        onClick={() => handleClose(option.action)}
                        title={option.action}
                    >
                        <div>
                            <img
                                className="deleteIcon"
                                src={option.src}
                                alt={option.title}
                                title={option.title}
                                style={{ width: "18px", height: "18px" }}
                            />
                        </div>
                        {option.action}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}
