import { Card, CardActions, CardContent, Typography } from "@mui/material";
import React from "react";
import { ButtonComponent } from "../button/button";
import Inputs from "../Inputs";
import http from "../../bae-client/HTTP";
import { useDispatch } from "react-redux";
import { setSnackbarAction } from "../../services/services/app";
import Loader from "./Loader";

const Attribute = ({ options = [], reload, setReload }) => {
    const dispatch = useDispatch();
    const [attributeName, setAttributeName] = React.useState("");
    const [regularExpression, setRegularExpression] = React.useState("");
    const [showLoader, setShowLoader] = React.useState(false);

    const handleDoneClick = () => {
        const activeFolder = options[options.length - 1];
        const objectId = activeFolder?.id;
        const objectType =
            activeFolder?.type?.charAt(0).toUpperCase() +
            activeFolder?.type?.slice(1);
        setShowLoader(true);
        const payload = {
            attributeName,
            regularExpression,
        };
        http.sendRestRequest(
            `object/attributes?objectId=${objectId}&objectType=${objectType}`,
            "POST",
            payload
        )
            .then(() => {
                setAttributeName("");
                setRegularExpression("");
                setShowLoader(false);
                setReload(true);
                dispatch(
                    setSnackbarAction({
                        message: "Attribute created successfully!",
                        open: true,
                        severity: "success",
                    })
                );
            })
            .catch(() => {
                dispatch(
                    setSnackbarAction({
                        message: "Something went wrong",
                        open: true,
                        severity: "error",
                    })
                );
            });
    };
    const handleCancelClick = () => {
        setAttributeName("");
        setRegularExpression("");
    };

    return (
        <>
            <Card>
                <CardContent sx={{ padding: "8px" }}>
                    <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                    >
                        {"Attribute Name"}
                    </Typography>
                    <Inputs
                        type={"default"}
                        placeholder={"Attribute Name"}
                        onChange={(e) => setAttributeName(e.target.value)}
                        value={attributeName}
                    />
                    <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                    >
                        {"Regular Expression"}
                    </Typography>
                    <Inputs
                        type={"default"}
                        placeholder={"Regular Expression"}
                        onChange={(e) => setRegularExpression(e.target.value)}
                        value={regularExpression}
                    />
                </CardContent>
                <CardActions
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <ButtonComponent
                        value={"Cancel"}
                        type={"outlined"}
                        text={"Cancel"}
                        BtnHandler={handleCancelClick}
                    />
                    <ButtonComponent
                        value={"Done"}
                        type={"primary"}
                        text={"Done"}
                        BtnHandler={handleDoneClick}
                        disable={
                            !attributeName.trim() || !regularExpression.trim()
                        }
                    />
                </CardActions>
                <Loader show={showLoader} />
            </Card>
        </>
    );
};

export default Attribute;
