import { Alert } from "bootstrap";
import React, { useState, useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import AlertManager from "../../../../../../bae-client/AlertManager";
import {
    fetchFilterData,
    openInstance,
} from "../../../../../../actions/DashboardActions";
import { AlignVerticalCenterTwoTone } from "@mui/icons-material";
import { setActivePage, setActiveRipple } from "../../../../../../actions";
import http from "../../../../../../bae-client/HTTP";
import Loader from "../../../../../stylized/Loader";
import { getDateByFormat } from "../../../../../../bae-client/Utils";
import CustomSnackbar from "../../../../../stylized/CustomSnackbar/CustomSnackbar";
import AddDocuments from "../../document-chain/modals/AddDocuments";
import {
    setEmailRecievedAction,
    setSnackbarAction,
} from "../../../../../../services/services/app";
import AddInstance from "../../document-chain/modals/AddInstance";
export default function Alerts({ setAllAlerts, allAlerts }) {
    const dispatch = useDispatch();
    const [message, setMessage] = useState("");
    const setOpenDialog = (flag) => setOpenSnackMsg(flag);
    const [openSnackMsg, setOpenSnackMsg] = useState(false);
    const [isFlowAlert, setIsFlowAlert] = useState(false);
    const [isDocumentAlert, setIsDocumentAlert] = useState(false);
    const [selectedAlert, setSelectedAlert] = useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [genericIdToNameMap, setGenericIdToNameMap] = useState(null);
    const [showLoader, setShowLoader] = React.useState(false);
    const [showDocumentModal, setShowDocumentModal] = React.useState(false);
    const [values, setValues] = React.useState(null);
    const [flowInfo, setFlowInfo] = React.useState(null);
    const [showAddInstanceModal, setShowAddInstanceModal] = useState(false);
    const [selectedGenericInstances, setSelectedGenericInstances] = useState(
        []
    );
    const [selectedRow, setSelectedRow] = React.useState(null);
    const [selectedInstance, setSelectedInstance] = React.useState(null);
    const [documentIdState, setDocumentIdState] = React.useState(null);
    const emailRecieved = useSelector((state) => state.emailRecieved);

    useEffect(() => {
        setShowLoader(true);
        http.sendRestRequest("documentChain/generic/list").then((flows) => {
            let genericIdToNameMap = new Map();
            flows.forEach((filterItem) => {
                genericIdToNameMap.set(
                    filterItem.genericId,
                    filterItem.genericDisplayName
                );
            });
            setShowLoader(false);
            setGenericIdToNameMap(genericIdToNameMap);
            fetchLatestFlowsData();
        });
    }, []);
    useEffect(() => {
        getAlerts();
    }, [genericIdToNameMap]);
    useEffect(() => {
        if (emailRecieved) {
            getAlerts();
            dispatch(setEmailRecievedAction(null));
        }
    }, emailRecieved);
    const getAlerts = () => {
        let alertManager = new AlertManager();
        alertManager
            .loadDataByAPI()
            .then((resp) => {
                setAllAlerts(resp);
            })
            .catch((errResp) => {
                console.log(errResp);
            });
    };

    const closeInstance = () => {
        setShowAddInstanceModal(false);
        setShowDocumentModal(true);
    };
    const openAddInstanceModal = () => {
        setShowAddInstanceModal(true);
        setShowDocumentModal(false);
    };

    const fetchLatestFlowsData = () => {
        http.sendRestRequest("documentChain/objects")
            .then((response) => {
                dispatch(fetchFilterData(response));
            })
            .catch((err) => console.log(err));
    };
    const addNewInstance = (name, tags, branchId, branchName) => {
        setShowLoader(true);
        setShowAddInstanceModal(false);
        let encodedFamilyTags = encodeURIComponent(tags);
        http.sendRestRequest(
            `documentChain/instance?genericId=${
                selectedRow.id
            }&instanceName=${encodeURIComponent(
                name
            )}&familyTags=${encodedFamilyTags}&branchId=${branchId}&branchName=${branchName}`,
            "POST"
        )
            .then(() => {
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: "Instance created successfully!",
                    })
                );
                http.sendRestRequest(
                    `documentChain/instance/list?genericId=${selectedRow.id}`
                )
                    .then((instanceData) => {
                        setShowLoader(false);
                        const prevInstances = [...selectedGenericInstances].map(
                            (instance) => instance.instanceId
                        );
                        setSelectedGenericInstances(instanceData);
                        const newInstance = instanceData.filter(
                            (instance) =>
                                !prevInstances.includes(instance.instanceId)
                        );
                        setSelectedInstance(newInstance[0]);
                        setShowDocumentModal(true);
                    })
                    .catch((error) => {
                        setShowLoader(false);
                        dispatch(
                            setSnackbarAction({
                                open: true,
                                message: error.error,
                                severity: "error",
                            })
                        );
                    });
                fetchLatestFlowsData();
            })
            .catch((error) => {
                setShowLoader(false);
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: error.error,
                        severity: "error",
                    })
                );
            });
    };
    const getFlowAlertIcon = (alert) => {
        let src =
            alert.blockType.trim().toLowerCase() === "optional" ||
            alert.exceptionType === "SKIPPED"
                ? "./images/yellowAlert.png"
                : alert.exceptionType === "PROACTIVE ALERT"
                ? "./images/Flow/t proactive.svg"
                : "./images/redAlert.png";

        return src;
    };

    const getAlertIcon = (alert) => {
        let src = "";
        if (alert.alertType.toLowerCase() === "alert") {
            src = "./images/redAlert.svg";
        } else if (alert.alertType.toLowerCase() === "reminder") {
            src = "./images/remiderIcon.svg";
        } else if (alert.alertType.toLowerCase() === "notification") {
            src = "./images/Vector.png";
        } else if (alert.alertType.toLowerCase() === "ripple") {
            src = "./images/Ripple.svg";
        } else if (alert.alertType.toLowerCase() === "menu") {
            src = "./images/alertMenu.png";
        } else if (alert.alertType.toLowerCase() === "integration") {
            src = "./images/Integrations.png";
        } else if (
            alert.alertType.toLowerCase() === "document addition" ||
            alert.alertType.toLowerCase() === "document intersection"
        ) {
            src = "./images/Subtract.png";
        }
        return src;
    };

    const renderFlowAlertMenu = () => {
        return (
            <Menu
                id="demo-customized-menu"
                MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose}>
                    <div>
                        <img
                            className="deleteIcon"
                            title="Open Instance"
                            src="./images/Rename.svg"
                            alt="Open Instance"
                        />
                        <span className="btn_title" title="Open Instance">
                            Open Instance
                        </span>
                    </div>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <div>
                        <img
                            className="deleteIcon"
                            title="Close Alert"
                            src="./images/fileTypeIcon/close.png"
                            alt="Close Alert"
                        />
                        <span className="btn_title" title="Close Alert">
                            Close Alert
                        </span>
                    </div>
                </MenuItem>
            </Menu>
        );
    };
    const handleClose = (e) => {
        if (e && e.target && e.target.innerText) {
            if (e.target.innerText === "Open Instance") {
                openInstancePage();
            }
            if (e.target.innerText === "Close Alert") {
                closeAlert();
            }
            if (e.target.innerText === "Attach to Flow") {
                //attachToFlow();
                setShowLoader(true);
                if (selectedAlert?.clickActionParameters) {
                    let flowName,
                        instanceName,
                        stepName = "";
                    const data =
                        selectedAlert?.clickActionParameters?.split("&&");
                    const documentId = data[0];
                    setDocumentIdState(documentId);
                    if (documentId) {
                        http.sendRestRequest("document/documentInfo", "POST", [
                            parseInt(documentId),
                        ])
                            .then((result) => {
                                const locationIds =
                                    result[0]?.locationIds?.split("\\");
                                const workspaceId = locationIds[0];
                                const spaceId = locationIds[1];
                                let folderId;
                                if (locationIds.length === 2) {
                                    folderId = 0;
                                } else {
                                    folderId =
                                        locationIds[locationIds.length - 1];
                                }

                                const value = {
                                    workSpaceId: workspaceId,
                                    spaceId,
                                    folderId:
                                        folderId === spaceId ? "0" : folderId,
                                    documentId,
                                };
                                setValues(value);
                                const flowInfo = data[1]?.split(":");
                                if (flowInfo?.length > 1)
                                    if (flowInfo[0] === "Flow") {
                                        flowName = flowInfo[1];
                                    } else if (flowInfo[0] === "Instance") {
                                        const arr = flowInfo[1].split("/");
                                        flowName = arr[0];
                                        instanceName = arr[1];
                                    } else if (flowInfo[0] === "Step") {
                                        const arr = flowInfo[1].split("/");
                                        flowName = arr[0];
                                        instanceName = arr[1];
                                        stepName = arr[arr.length - 1];
                                    }
                                setFlowInfo({
                                    flowName,
                                    instanceName,
                                    stepName,
                                });
                                setShowDocumentModal(true);
                                setShowLoader(false);
                            })
                            .catch((err) => {
                                console.error(err);
                                setShowLoader(false);
                                dispatch(
                                    setSnackbarAction({
                                        snackbarOpen: true,
                                        snackbarType: "error",
                                        snackbarMessage:
                                            err?.error ||
                                            "Something went wrong!",
                                    })
                                );
                            });
                    }
                }
            }
        }

        setAnchorEl(null);
    };
    const handleClick = (event, alert) => {
        if (alert.hasOwnProperty("exceptionLogObjectId")) {
            setIsFlowAlert(true);
            setIsDocumentAlert(false);
            setSelectedAlert(alert);
        } else if (alert.clickAction === "Flow.open") {
            setIsFlowAlert(false);
            setIsDocumentAlert(true);
            setSelectedAlert(alert);
        } else {
            setIsFlowAlert(false);
            setIsDocumentAlert(false);
        }
        setAnchorEl(event.currentTarget);
    };
    const openInstancePage = () => {
        const libData = {
            genericId: selectedAlert.genericId,
            genericName: genericIdToNameMap.get(selectedAlert.genericId),
            instanceIds: [selectedAlert.instanceId],
        };
        dispatch(setActivePage("DocuChain"));
        dispatch(openInstance(libData));
    };
    const documentCloseAlert = () => {
        let payloadAlertIds = [
            {
                alertRegistryId: selectedAlert.alertRegistryId,
                status: "CLOSED",
            },
        ];
        setShowLoader(true);
        let url = "alert/clear";
        http.sendRestRequest(url, "PUT", payloadAlertIds)
            .then(() => {
                setOpenDialog(true);
                setMessage("Alert closed successfully!");
                getAlerts();
                setShowLoader(false);
            })
            .catch((e) => {
                console.log(e);
                setShowLoader(false);
            });
    };
    const closeAlert = () => {
        if (isDocumentAlert) {
            documentCloseAlert();
        } else {
            const libData = {
                genericId: selectedAlert.genericId,
                exceptionLogObjectId: selectedAlert.exceptionLogObjectId,
                status: "CLOSED",
            };
            let url = "documentChain/exception/status";
            setShowLoader(true);
            http.sendRestRequest(url, "PUT", [libData]).then(
                (documentObjects) => {
                    setOpenDialog(true);
                    setMessage("Alert closed successfully!");
                    getAlerts();
                    setShowLoader(false);
                }
            );
        }
    };
    const alertClickHandler = (alert) => {
        if (alert.alertType === "ripple") {
            let rippleRegistryId = parseInt(alert.clickActionParameters);
            let url = `ripple/rippleRegistry/${rippleRegistryId}`;
            http.sendRestRequest(url, "GET").then((resp) => {
                let rippleData = resp;
                rippleData["rippleId"] = resp.rippleRegistryId;
                dispatch(setActiveRipple(rippleData));
                dispatch(setActivePage("Ripple"));
            });
        }
    };
    return (
        <>
            {allAlerts.length > 0 ? (
                <div className="heading" style={{ paddingBottom: "10px" }}>
                    Alerts
                </div>
            ) : null}
            <div className="alert-list">
                {allAlerts.map((alert) => {
                    if (alert.status === "CLOSED") {
                        return;
                    }
                    return (
                        <div className="alert-home">
                            <div className="alert-list">
                                <div
                                    className="alertBody dashboardAlert"
                                    style={{
                                        position: "relative",
                                        cursor:
                                            alert.alertType === "ripple"
                                                ? "pointer"
                                                : "auto",
                                    }}
                                    onClick={() => alertClickHandler(alert)}
                                >
                                    <div className="alertIcon col-1 p-0 doCenter">
                                        <img
                                            src={
                                                alert.hasOwnProperty(
                                                    "exceptionLogObjectId"
                                                )
                                                    ? getFlowAlertIcon(alert)
                                                    : getAlertIcon(alert)
                                            }
                                        />
                                    </div>
                                    <div className="alertTitle textEllipsis col-9 pr-0">
                                        <div style={{ fontWeight: "700" }}>
                                            {alert.hasOwnProperty(
                                                "exceptionLogObjectId"
                                            )
                                                ? alert.exceptionType
                                                : alert.hasOwnProperty(
                                                      "alertName"
                                                  ) &&
                                                  alert.alertType ===
                                                      "ripple" &&
                                                  alert.alertName
                                                      .split(":")[0]
                                                      .trim() === ""
                                                ? `Unsaved Ripple ${alert.alertName}`
                                                : alert.alertName}
                                        </div>
                                        {alert.hasOwnProperty(
                                            "exceptionLogObjectId"
                                        ) ? (
                                            alert.exceptionType ===
                                            "KEY MISMATCH" ? (
                                                <div
                                                    style={{
                                                        fontWeight: "400",
                                                        height: "50px",
                                                    }}
                                                    class="listHeader textEllipsis"
                                                    dangerouslySetInnerHTML={{
                                                        __html: alert.exceptionText,
                                                    }}
                                                >
                                                    {/* {data.name} */}
                                                </div>
                                            ) : (
                                                <div
                                                    style={{
                                                        fontWeight: "400",
                                                    }}
                                                    class="listHeader textEllipsis"
                                                    dangerouslySetInnerHTML={{
                                                        __html: alert.exceptionText,
                                                    }}
                                                >
                                                    {/* {data.name} */}
                                                </div>
                                            )
                                        ) : (
                                            <div
                                                style={{ fontWeight: "400" }}
                                                class="listHeader textEllipsis"
                                                title={alert.alertBody}
                                            >
                                                {alert.alertBody}
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        className="alertBtn col-2 doCenter p-0 cursorPointer"
                                        onClick={(event) =>
                                            !alert.hasOwnProperty(
                                                "alertRegistryId"
                                            ) ||
                                            alert.alertType ===
                                                "Document Intersection"
                                                ? handleClick(event, alert)
                                                : null
                                        }
                                        style={{
                                            position: "relative",
                                            textAlign: "end",
                                        }}
                                    >
                                        <img
                                            onClick={(event) =>
                                                !alert.hasOwnProperty(
                                                    "alertRegistryId"
                                                )
                                                    ? handleClick(event, alert)
                                                    : null
                                            }
                                            style={{
                                                cursor:
                                                    !alert.hasOwnProperty(
                                                        "alertRegistryId"
                                                    ) ||
                                                    alert.alertType ===
                                                        "Document Intersection"
                                                        ? "pointer"
                                                        : "not-allowed",
                                                position: "absolute",
                                                top: "-25px",
                                                right: "7px",
                                            }}
                                            id="img_19573"
                                            src="./images/alertMenu.png"
                                            title="options"
                                            alt="options"
                                        />
                                        {alert.hasOwnProperty(
                                            "exceptionLogObjectId"
                                        ) && (
                                            <div
                                                className="textEllipsis"
                                                style={{
                                                    position: "absolute",
                                                    padding: "8px",
                                                    bottom: "-20px",
                                                    right: "0px",
                                                    fontSize: "10px",
                                                    fontWeight: "700",
                                                    width: "100%",
                                                }}
                                                title={
                                                    genericIdToNameMap &&
                                                    genericIdToNameMap.get(
                                                        alert.genericId
                                                    )
                                                }
                                            >
                                                {genericIdToNameMap &&
                                                    genericIdToNameMap.get(
                                                        alert.genericId
                                                    )}
                                            </div>
                                        )}
                                        <div
                                            className="textEllipsis alert-time"
                                            title={getDateByFormat(
                                                alert.createdOn,
                                                "month dd,yyyy",
                                                "alert"
                                            )}
                                        >
                                            {getDateByFormat(
                                                alert.createdOn,
                                                "month dd,yyyy",
                                                "alert"
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            {isFlowAlert ? renderFlowAlertMenu() : null}
            {isDocumentAlert ? (
                <Menu
                    id="demo-customized-menu"
                    MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleClose}>
                        <div>
                            <img
                                className="deleteIcon"
                                title="Attach to Flow"
                                src="./images/Rename.svg"
                                alt="Attach to Flow"
                            />
                            <span className="btn_title" title="Attach to Flow">
                                Attach to Flow
                            </span>
                        </div>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        <div>
                            <img
                                className="deleteIcon"
                                title="Close Alert"
                                src="./images/fileTypeIcon/close.png"
                                alt="Close Alert"
                            />
                            <span className="btn_title" title="Close Alert">
                                Close Alert
                            </span>
                        </div>
                    </MenuItem>
                </Menu>
            ) : null}
            {showDocumentModal ? (
                <AddDocuments
                    documentId={documentIdState}
                    setSelectedRow={setSelectedRow}
                    openAddInstanceModal={openAddInstanceModal}
                    values={values}
                    folderId={values?.folderId}
                    showDocumentModal={showDocumentModal}
                    closeModal={() => setShowDocumentModal(false)}
                    flowInfo={flowInfo}
                    documentCloseAlert={documentCloseAlert}
                    // flowInfo={{
                    //     flowName: "21 st march",
                    //     instanceName: "Instance 1",
                    //     stepName: "",
                    // }}
                />
            ) : null}
            {showAddInstanceModal ? (
                <AddInstance
                    selectedGenericInstances={selectedGenericInstances}
                    selectedInstance={selectedInstance}
                    showAddInstanceModal={showAddInstanceModal}
                    closeModal={closeInstance}
                    currentFamilyTags={[]}
                    currentInstanceName={""}
                    heading={"Add New Instance"}
                    branchsId={"1"}
                    branchsName={"1"}
                    disabled={false}
                    editNewInstance={""}
                    addNewInstance={addNewInstance}
                ></AddInstance>
            ) : null}
            <Loader show={showLoader}></Loader>
            <CustomSnackbar
                open={openSnackMsg}
                setOpen={setOpenDialog}
                alertMessage={message}
            ></CustomSnackbar>
        </>
    );
}
