import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import { ButtonComponent } from "../../button/button";

const CustomSearchDropdown = ({
    items,
    multiSelect,
    label,
    id,
    title,
    isSearchable,
    handleSelection,
    selection,
    showInstanceCreate,
    customClassName,
    openAddInstanceModal,
    dropdownPosition,
    customWidth,
    customHeight,
    disable,
    returnSelectionOnFocusOut = false
}) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const dropdownRef = useRef(null);
    
    useEffect(() => {
        if(returnSelectionOnFocusOut && showDropdown === undefined) {
            handleSelection(0, selectedOptions);
        }
    }, [showDropdown]);

    useEffect(() => {
        if (selection) {
            if (Array.isArray(selection)) {
                setSelectedOptions(selection);
            } else {
                selection?.text && setSelectedOptions([selection]);
            }
        } else setSelectedOptions([]);
    }, [selection]);

    const handleInputChange = (e) => {
        if (!disable) {
            setSearchTerm(e.target.value);
        }
    };

    const handleOptionClick = (option) => {
        if (!disable && option !== undefined) {
            let selectedItem = [];
            if (multiSelect) {
                if (selectedOptions.some((o) => o.text === option.text)) {
                    selectedItem = selectedOptions.filter(
                        (o) => o.text !== option.text
                    );
                } else {
                    selectedItem = [...selectedOptions, option];
                }
                setSelectedOptions(selectedItem);
                !returnSelectionOnFocusOut && handleSelection(0, selectedItem);
            } else if (option.value === "create-instance") {
                openAddInstanceModal();
                return;
            } else {
                setSelectedOptions([option]);
                !returnSelectionOnFocusOut && handleSelection(0, option);
                setShowDropdown(false);
            }
        }

        setSearchTerm("");
    };

    const handleSelectAll = (e) => {
        if (!disable) {
            if (e.target.checked) {
                setSelectedOptions(items);
                !returnSelectionOnFocusOut && handleSelection(0, items);
            } else {
                setSelectedOptions([]);
                !returnSelectionOnFocusOut && handleSelection(0, []);
            }
        }
    };

    const filteredOptions = items
        .filter((option) =>
            option.text.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .map((option) => option.text.toLowerCase())
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((uniqueText) =>
            items.find((item) => item.text.toLowerCase() === uniqueText)
        );

    const toggleDropdown = () => {
        if (!disable) {
            setShowDropdown((prev) => !prev);
        }
    };

    const isAllSelected =
        items.length > 0 && selectedOptions.length === items.length;

    const handleClickOutside = (e) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(e.target) &&
            !disable
        ) {
            setShowDropdown(undefined);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div
            className={`${
                customClassName ? customClassName : "select-search-container"
            } ${disable ? "disabled" : ""}`}
            ref={dropdownRef}
        >
            <div
                className={`${
                    customWidth
                        ? "dropdownShare-container"
                        : "dropdown-container"
                } ${showDropdown ? "dropdown-open" : ""}`}
                onClick={toggleDropdown}
            >
                <div
                    className="select-search-value"
                    id={id}
                    title={
                        multiSelect
                            ? selectedOptions
                                  .map((opt) => opt.text)
                                  .join(", ") || label
                            : selectedOptions.length === 1
                            ? selectedOptions[0].text
                                ? selectedOptions[0].text
                                : label
                            : label
                    }
                >
                    {multiSelect
                        ? selectedOptions.map((opt) => opt.text).join(", ") ||
                          label
                        : selectedOptions.length === 1
                        ? selectedOptions[0].text
                            ? selectedOptions[0].text
                            : label
                        : label}
                </div>
                <div className="dropdown-icon">
                    <svg
                        className={`MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiSelect-iconOutlined css-hfutr2-MuiSvgIcon-root-MuiSelect-icon ${
                            showDropdown ? "icon-rotate-up" : "icon-rotate-down"
                        }`}
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        data-testid="ArrowDropDownIcon"
                        id={
                            showDropdown !== undefined
                                ? `${showDropdown}_ArrowDropDownIcon`
                                : "ArrowDropDownIcon"
                        }
                    >
                        <path d="M7 10l5 5 5-5z"></path>
                    </svg>
                </div>
            </div>
            {showDropdown && (
                <div
                    className={
                        dropdownPosition
                            ? "dropdown-list1 textEllipsis invisibleScroller"
                            : title === "Save SPACE" || title === "Share space"
                            ? "dropdown-list_wk textEllipsis invisibleScroller"
                            : "dropdown-list textEllipsis invisibleScroller"
                    }
                    style={{ maxHeight: customHeight && customHeight }}
                >
                    {isSearchable && items.length ? (
                        <div className="dropdown-input">
                            <input
                                type="text"
                                value={searchTerm}
                                onChange={handleInputChange}
                                placeholder="Search"
                                disabled={disable}
                            />
                        </div>
                    ) : null}
                    {showInstanceCreate ? (
                        <div style={{ width: "100%" }}>
                            <ButtonComponent
                                type={"ghost"}
                                text={"Add Instance"}
                                icon={"./images/fileTypeIcon/plus 3.svg"}
                                BtnHandler={(e) => {
                                    e.stopPropagation();
                                    openAddInstanceModal();
                                }}
                                disabled={disable}
                            />
                        </div>
                    ) : null}
                    {multiSelect && items.length ? (
                        <div className="select-all-option">
                            <input
                                type="checkbox"
                                checked={isAllSelected}
                                onChange={handleSelectAll}
                                style={{ marginRight: "8px" }}
                                disabled={disable}
                            />
                            Select All
                        </div>
                    ) : null}
                    <ul className="select-search-options">
                        {filteredOptions.length > 0 ? (
                            filteredOptions.map((option) => (
                                <li
                                    key={option.id}
                                    title={option.text}
                                    onClick={() => handleOptionClick(option)}
                                >
                                    {multiSelect ? (
                                        <input
                                            type="checkbox"
                                            checked={selectedOptions.some(
                                                (o) => o.id === option.id
                                            )}
                                            onChange={() =>
                                                handleOptionClick(option)
                                            }
                                            onClick={(e) => e.stopPropagation()} // Stop event propagation to prevent triggering the li onClick
                                            style={{ marginRight: "8px" }}
                                            disabled={disable}
                                        />
                                    ) : null}
                                    {option.text}
                                </li>
                            ))
                        ) : (
                            <li>No Result Found</li>
                        )}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default CustomSearchDropdown;
