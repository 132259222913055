import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import "../style.css";

function createData(
    name,
    documentCount,
    onTime,
    delayed,
    type,
    proactiveAlert,
    stepId,
    selection
) {
    return {
        name,
        documentCount,
        onTime,
        delayed,
        type,
        proactiveAlert,
        stepId,
        selection,
    };
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: "stepId",
        numeric: false,
        disablePadding: false,
        label: "Step ID",
    },
    {
        id: "name",
        numeric: false,
        disablePadding: false,
        label: "Step Name",
    },
    {
        id: "documentCount",
        numeric: false,
        disablePadding: false,
        label: "Total Documents",
    },
    {
        id: "onTime",
        numeric: false,
        disablePadding: false,
        label: "On Time Documents",
    },
    {
        id: "delayed",
        numeric: false,
        disablePadding: false,
        label: "Delayed Documents",
    },
    {
        id: "Proactive Alerts",
        numeric: false,
        disablePadding: false,
        label: "Proactive Alerts",
    },
];
const compareHeadCells = [
    {
        id: "name",
        numeric: false,
        disablePadding: false,
        label: "Step Name",
    },
    {
        id: "selection",
        numeric: false,
        disablePadding: false,
        label: "Selection",
    },
    {
        id: "documentCount",
        numeric: false,
        disablePadding: false,
        label: "Total Documents",
    },
    {
        id: "onTime",
        numeric: false,
        disablePadding: false,
        label: "On Time Documents",
    },
    {
        id: "delayed",
        numeric: false,
        disablePadding: false,
        label: "Delayed Documents",
    },
    {
        id: "Proactive Alerts",
        numeric: false,
        disablePadding: false,
        label: "Proactive Alerts",
    },
];
const compareHeadCelss = [
    {
        id: "stepId",
        numeric: false,
        disablePadding: false,
        label: "Step ID",
    },
    {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: "Step Name",
    },
    {
        id: "filter",
        numeric: false,
        disablePadding: true,
        label: "Filter Name",
    },
    {
        id: "documentCount",
        numeric: false,
        disablePadding: false,
        label: "Total Documents",
    },
    {
        id: "onTime",
        numeric: false,
        disablePadding: false,
        label: "On Time Documents",
    },
    {
        id: "delayed",
        numeric: false,
        disablePadding: false,
        label: "Delayed Documents",
    },
    {
        id: "Proactive Alerts",
        numeric: false,
        disablePadding: false,
        label: "Proactive Alerts",
    },
];

function EnhancedTableHead(props) {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
        type,
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead sx={{ position: "sticky", top: "0px", background: "white" }}>
            <TableRow>
                {/* <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={
                            numSelected > 0 && numSelected < rowCount
                        }
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            "aria-label": "select all desserts",
                        }}
                    />
                </TableCell> */}
                {type === "compare"
                    ? compareHeadCells.map((headCell) => (
                          <TableCell
                              key={headCell.id}
                              align={headCell.numeric ? "right" : "left"}
                              padding={
                                  headCell.disablePadding ? "none" : "normal"
                              }
                              //   sortDirection={
                              //       orderBy === headCell.id ? order : false
                              //   }
                          >
                              {headCell.label}
                          </TableCell>
                      ))
                    : headCells.map((headCell) => (
                          <TableCell
                              key={headCell.id}
                              align={headCell.numeric ? "right" : "left"}
                              padding={
                                  headCell.disablePadding ? "none" : "normal"
                              }
                              sortDirection={
                                  orderBy === headCell.id ? order : false
                              }
                          >
                              <TableSortLabel
                                  active={orderBy === headCell.id}
                                  direction={
                                      orderBy === headCell.id ? order : "asc"
                                  }
                                  onClick={createSortHandler(headCell.id)}
                              >
                                  {headCell.label}
                                  {orderBy === headCell.id ? (
                                      <Box component="span" sx={visuallyHidden}>
                                          {order === "desc"
                                              ? "sorted descending"
                                              : "sorted ascending"}
                                      </Box>
                                  ) : null}
                              </TableSortLabel>
                          </TableCell>
                      ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(
                            theme.palette.primary.main,
                            theme.palette.action.activatedOpacity
                        ),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: "1 1 100%" }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: "1 1 100%" }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Nutrition
                </Typography>
            )}

            {/* {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )} */}
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default function CustomTable({
    spaceData,
    type,
    height,
    openSpaceOrFolder,
    setSelectedItems,
    popup,
    filterOneData,
    filterTwoData,
    filterOne,
    filterTwo,
}) {
    const [order, setOrder] = React.useState("asc");
    const [rows, setRows] = React.useState([]);
    const [orderBy, setOrderBy] = React.useState("calories");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);

    React.useEffect(() => {
        if (
            spaceData &&
            (spaceData.length || typeof spaceData === "object") &&
            type !== "compare"
        ) {
            if (type === "workspace")
                setRows(
                    spaceData.map((space) =>
                        createData(
                            space.spaceName,
                            space.updatedOn.split(" ")[0],
                            space.createdOn.split(" ")[0],
                            space.spaceId,
                            "space"
                        )
                    )
                );
            else if (type === "report") {
                setRows(
                    spaceData.map((space) =>
                        createData(
                            space.genericStepName,
                            space.documentCount,
                            space.documentCountOnTime,
                            space.documentCountDelayed,
                            "report",
                            space.totalProactiveAlerts,
                            space.stepOrder
                        )
                    )
                );
            } else {
                const data = [];
                for (const key in spaceData) {
                    if (Object.hasOwnProperty.call(spaceData, key)) {
                        data.push({ ...spaceData[key], objectId: key });
                    }
                }
                setRows(
                    data.map((object) =>
                        createData(
                            object.objectName,
                            object.updatedOn.split(" ")[0],
                            object.createdOn.split(" ")[0],
                            object.objectId,
                            object.documentId ? "file" : "folder"
                        )
                    )
                );
            }
            setSelected([]);
            setPage(0);
            setRowsPerPage(5);
        }
    }, [spaceData, filterOneData, filterTwoData, filterOne, filterTwo]);

    React.useEffect(() => {
        if (type === "compare") {
            if (
                filterOneData &&
                filterTwoData &&
                filterOneData.length &&
                filterTwoData.length
            ) {
                const data = [];
                if (filterOneData.length - filterTwoData.length) {
                    filterOneData.forEach((space) => {
                        data.push(
                            createData(
                                space.genericStepName,
                                space.documentCount,
                                space.documentCountOnTime,
                                space.documentCount - space.documentCountOnTime,
                                "report",
                                space.totalProactiveAlerts,
                                space.stepOrder,
                                filterOne
                            )
                        );
                        const filterTwoRow = filterTwoData.filter(
                            (element) =>
                                element.genericStepName ===
                                space.genericStepName
                        );
                        if (filterTwoRow.length)
                            data.push(
                                createData(
                                    "",
                                    filterTwoRow[0].documentCount,
                                    filterTwoRow[0].documentCountOnTime,
                                    filterTwoRow[0].documentCount -
                                        filterTwoRow[0].documentCountOnTime,
                                    "report",
                                    filterTwoRow[0].totalProactiveAlerts,
                                    filterTwoRow[0].stepOrder,
                                    filterTwo
                                )
                            );
                        // else
                        //     data.push(
                        //         createData(
                        //             "",
                        //             "N/A",
                        //             "N/A",
                        //             "N/A",
                        //             "report",
                        //             "N/A",
                        //             "N/A",
                        //             filterTwo
                        //         )
                        //     );
                    });
                    const existingData = data.map((element) => element.name);
                    filterTwoData.forEach((space) => {
                        const existingElement = existingData.includes(
                            space.genericStepName
                        );
                        if (!existingElement)
                            data.push(
                                createData(
                                    space.genericStepName,
                                    space.documentCount,
                                    space.documentCountOnTime,
                                    space.documentCount -
                                        space.documentCountOnTime,
                                    "report",
                                    space.totalProactiveAlerts,
                                    space.stepOrder,
                                    filterTwo
                                )
                            );
                        const filterOneRow = filterOneData.filter(
                            (element) =>
                                element.genericStepName ===
                                space.genericStepName
                        );
                        // if (!filterOneRow.length)
                        //     data.push(
                        //         createData(
                        //             "",
                        //             "N/A",
                        //             "N/A",
                        //             "N/A",
                        //             "report",
                        //             "N/A",
                        //             "N/A",
                        //             filterOne
                        //         )
                        //     );
                    });
                } else {
                    filterTwoData.forEach((space) => {
                        data.push(
                            createData(
                                space.genericStepName,
                                space.documentCount,
                                space.documentCountOnTime,
                                space.documentCount - space.documentCountOnTime,
                                "report",
                                space.totalProactiveAlerts,
                                space.stepOrder,
                                filterTwo
                            )
                        );
                        const filterOneRow = filterOneData.filter(
                            (element) =>
                                element.genericStepName ===
                                space.genericStepName
                        );
                        if (filterOneRow.length)
                            data.push(
                                createData(
                                    "",
                                    filterOneRow[0].documentCount,
                                    filterOneRow[0].documentCountOnTime,
                                    filterOneRow[0].documentCount -
                                        filterOneRow[0].documentCountOnTime,
                                    "report",
                                    filterOneRow[0].totalProactiveAlerts,
                                    filterOneRow[0].stepOrder,
                                    filterOne
                                )
                            );
                        // else
                        //     data.push(
                        //         createData(
                        //             "",
                        //             "N/A",
                        //             "N/A",
                        //             "N/A",
                        //             "report",
                        //             "N/A",
                        //             "N/A",
                        //             filterOne
                        //         )
                        //     );
                    });
                    const existingData = data.map((element) => element.name);
                    filterOneData.forEach((space) => {
                        const existingElement = existingData.includes(
                            space.genericStepName
                        );
                        if (!existingElement)
                            data.push(
                                createData(
                                    space.genericStepName,
                                    space.documentCount,
                                    space.documentCountOnTime,
                                    space.documentCount -
                                        space.documentCountOnTime,
                                    "report",
                                    space.totalProactiveAlerts,
                                    space.stepOrder,
                                    filterOne
                                )
                            );
                        const filterTwoRow = filterTwoData.filter(
                            (element) =>
                                element.genericStepName ===
                                space.genericStepName
                        );
                        // if (!filterTwoRow.length)
                        //     data.push(
                        //         createData(
                        //             "",
                        //             "N/A",
                        //             "N/A",
                        //             "N/A",
                        //             "report",
                        //             "N/A",
                        //             "N/A",
                        //             filterTwo
                        //         )
                        //     );
                    });
                }
                setRows([]);
                setRows(data);
            }
        }
    }, [filterOneData, filterTwoData, filterOne, filterTwo]);
    React.useEffect(() => {
        setSelectedItems(selected);
    }, [selected]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            // const newSelecteds = rows.map((n) => n.name);
            setSelected(rows);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id, item) => {
        const selectedIndex = selected.map((item) => item.id).indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, item);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id) =>
        selected.map((item) => item.id).indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const sortedRows = stableSort(rows, getComparator(order, orderBy));
    return (
        <Box sx={{ width: "100%", height: "100%" }}>
            <Paper
                sx={{
                    width: "100%",
                    position: "relative",
                    height: "100%",
                    borderRadius: "4px",
                    boxShadow: "none",
                }}
            >
                {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                <TableContainer
                    className="invisibleScroller"
                    sx={
                        popup
                            ? {
                                  overflowX: "auto",
                                  paddingLeft: "12px",
                                  paddingRight: "12px",
                                  overflowY: "auto",
                                  height: height,
                                  padding: "0px",
                              }
                            : {
                                  height: "90%",
                                  overflowX: "auto",
                                  paddingLeft: "12px",
                                  paddingRight: "12px",
                              }
                    }
                >
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? "small" : "medium"}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            type={type}
                        />
                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                            {sortedRows
                                // .slice(
                                //     page * rowsPerPage,
                                //     page * rowsPerPage + rowsPerPage
                                // )
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) =>
                                                handleClick(event, row.id, row)
                                            }
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.name}
                                            selected={isItemSelected}
                                        >
                                            {/* <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        "aria-labelledby":
                                                            labelId,
                                                    }}
                                                />
                                            </TableCell> */}
                                            {type !== "compare" && (
                                                <TableCell>
                                                    {row.stepId}
                                                </TableCell>
                                            )}
                                            {type !== "compare" ? (
                                                <TableCell
                                                    component="th"
                                                    id={labelId}
                                                    scope="row"
                                                    className="tableHead1"
                                                    // padding="none"
                                                    style={{
                                                        cursor: "pointer",
                                                        fontWeight: "700",
                                                    }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        openSpaceOrFolder(row);
                                                    }}
                                                >
                                                    {row.name}
                                                </TableCell>
                                            ) : index < sortedRows.length - 1 &&
                                              sortedRows[index + 1].name ===
                                                  "" ? (
                                                <TableCell
                                                    component="th"
                                                    id={labelId}
                                                    scope="row"
                                                    className="tableHead1"
                                                    // padding="none"
                                                    style={{
                                                        cursor: "pointer",
                                                        fontWeight: "700",
                                                    }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        openSpaceOrFolder(row);
                                                    }}
                                                    rowSpan={2}
                                                >
                                                    {row.name}
                                                </TableCell>
                                            ) : index < sortedRows.length - 1 &&
                                              row.name !== "" &&
                                              sortedRows[index + 1].name ? (
                                                <TableCell
                                                    component="th"
                                                    id={labelId}
                                                    scope="row"
                                                    className="tableHead1"
                                                    // padding="none"
                                                    style={{
                                                        cursor: "pointer",
                                                        fontWeight: "700",
                                                    }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        openSpaceOrFolder(row);
                                                    }}
                                                >
                                                    {row.name}
                                                </TableCell>
                                            ) : null}
                                            {type === "compare" &&
                                                index ===
                                                    sortedRows.length - 1 &&
                                                row.name && (
                                                    <TableCell
                                                        component="th"
                                                        id={labelId}
                                                        scope="row"
                                                        className="tableHead1"
                                                        // padding="none"
                                                        style={{
                                                            cursor: "pointer",
                                                            fontWeight: "700",
                                                        }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            openSpaceOrFolder(
                                                                row
                                                            );
                                                        }}
                                                    >
                                                        {row.name}
                                                    </TableCell>
                                                )}
                                            {type === "compare" && (
                                                <TableCell>
                                                    {row.selection}
                                                </TableCell>
                                            )}

                                            <TableCell>
                                                {row.documentCount > 0
                                                    ? row.documentCount
                                                    : 0}
                                            </TableCell>
                                            <TableCell>
                                                {row.onTime > 0
                                                    ? row.onTime
                                                    : 0}
                                            </TableCell>
                                            <TableCell>
                                                {row.delayed > 0
                                                    ? row.delayed
                                                    : 0}
                                            </TableCell>
                                            <TableCell>
                                                {row.proactiveAlert > 0
                                                    ? row.proactiveAlert
                                                    : 0}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    // sx={{ position: "absolute", bottom: "0px", right: "0px" }}
                /> */}
            </Paper>
            {/* <FormControlLabel
                control={
                    <Switch checked={dense} onChange={handleChangeDense} />
                }
                label="Dense padding"
            /> */}
        </Box>
    );
}
