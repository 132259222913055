export const setChatList = (chatListData) => {
    return {
        type: "CHAT_LIST",
        payload: chatListData,
    };
};
export const setActiveChat = (activeChatData) => {
    return async (dispatch) => {
        dispatch({
            type: "ACTIVE_CHAT_LIST",
            payload: activeChatData,
        });
    };
};
export const setSharedData = (activeChatData) => {
    return async (dispatch) => {
        dispatch({
            type: "SHARE_DATA",
            payload: activeChatData,
        });
    };
};
