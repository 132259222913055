import React, { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import * as Mixpanel from "../../../../../mix-panel/Mixpanel";
import "./Workspaces.css";
import { ButtonComponent } from "../../../../button/button";
import { useDispatch, useSelector } from "react-redux";
import WorkspaceModal from "./workspace-modal";
import { setActivePage, setHomeRedirection } from "../../../../../actions";
import { connect } from "react-redux";
import Loader from "../../../../stylized/Loader";
import {
    addSpaceAction,
    fetchSpaceListAction,
    setSnackbarAction,
} from "../../../../../services/services/app";
import http from "../../../../../bae-client/HTTP";
import RecentActivityManager from "../../../../../bae-client/RecentActivityManager";
import { validateFileType } from "./constant";
import AlertDialog from "../../../../stylized/AlertDialog";

const EmptyTable = ({
    activeSpace,
    parent,
    isWorkspace,
    fetchFolderData,
    breadcrumbData,
    setActivePage,
}) => {
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [alertDialogMessage, setAlertDialogMessage] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalType, setModalType] = useState("");
    const [existingName, setExistingName] = useState("");
    const [existingMatcher, setExistingMatcher] = useState([]);
    const [actionButtonName, setActionButtonName] = useState("Save");
    const [content, setContent] = useState("");
    const [disableOperations, setDisableOperations] = useState(false);
    const tableFileUplode = useRef(null);
    const [recentlyCreatedSpace, setRecentlyCreatedSpace] = useState("");
    const spaceListData = useSelector((state) => state.spaceListData);
    const activeWorkspace = useSelector((state) => state.activeWorkspace);
    // const activeSpace = useSelector((state) => state.activeSpace);
    const dispatch = useDispatch();
    const handleFlowChange = () => {
        setActivePage("DocuChain");
        dispatch(setHomeRedirection({ flag: true, type: "LibraryTab" }));
    };
    useEffect(() => {
        if (activeWorkspace && activeWorkspace.workspaceType === "SYSTEM")
            setDisableOperations(true);
    }, [activeWorkspace]);
    useEffect(() => {
        if (recentlyCreatedSpace !== "") {
            let recentSpace = spaceListData.find(
                (item) => item.spaceName.trim() === recentlyCreatedSpace.trim()
            );
            let recentActivity = new RecentActivityManager();
            let dataObj = {
                objectType: "SPACE",
                objectId: recentSpace.spaceId,
                description: `Created ${recentSpace.spaceName}`,
                path: `${activeWorkspace.workspaceId}`,
            };
            recentActivity
                .createRecentActivity(dataObj)
                .then((recentRes) => {})
                .catch((err) => console.log(err.error));
            setRecentlyCreatedSpace("");
        }
    }, [spaceListData]);
    const clearStates = () => {
        setModalTitle("");
        setModalType("");
        setExistingMatcher([]);
        setExistingName("");
        setShowLoader(false);
        setActionButtonName("Save");
        setContent("");
    };
    const addNewSpace = (spaceName) => {
        let data = {};
        data = {
            spaceName: spaceName.trim(),
            workspaceId: activeWorkspace.workspaceId,
        };
        setShowLoader(true);
        dispatch(addSpaceAction(data))
            .unwrap()
            .then((response) => {
                setRecentlyCreatedSpace(data.spaceName);
                clearStates();
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: "Space added successfully",
                    })
                );
                dispatch(fetchSpaceListAction(activeWorkspace.workspaceId));
            })
            .catch((error) => {
                clearStates();
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: "There is something wrong. Please try again",
                        type: "error",
                    })
                );
            });
    };

    const addNewFolder = (folderName) => {
        setShowLoader(true);
        let spaceId = activeSpace.id;
        let data = {
            spaceId: spaceId,
            objectName: folderName.trim(),
            parentRowId: parent.id === spaceId ? 0 : parent.id,
        };

        let url = "spacePersistence/folder";
        http.sendRestRequest(url, "POST", data)
            .then((resp) => {
                if (resp) {
                    let key = Object.keys(resp);
                    let recentActivity = new RecentActivityManager();
                    let dataobj = {
                        objectType: "FOLDER",
                        objectId: parseInt(key[0]),
                        description: `Created ${data.objectName}`,
                        path: `${activeWorkspace.workspaceId}//${activeSpace.id}`,
                    };
                    recentActivity
                        .createRecentActivity(dataobj)
                        .then((recentRes) => {
                            clearStates();
                        })
                        .catch((err) => console.log(err.error));
                    Mixpanel.callFolderManagementEvent("Add");
                    clearStates();
                    dispatch(
                        setSnackbarAction({
                            open: true,
                            message: "Folder added successfully",
                        })
                    );
                    fetchFolderData(
                        parent.id === spaceId ? { id: 0 } : parent,
                        true
                    );
                }
            })
            .catch((err) => {
                clearStates();
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: "There is something wrong. Please try again",
                        type: "error",
                    })
                );
            });
    };
    const onChangeFileUpload = (event) => {
        let filesData = event.target ? event.target.files : event;
        if (!filesData.length) return;
        let formFileData = Array.from(filesData);
        let fileError = false;
        if (formFileData.length > 0) {
            let chosenFile = Array.from(formFileData);
            chosenFile.forEach((files) => {
                let fileNames = [];
                let fileSizeArray = [];
                let fileName = files.name;
                fileNames.push(fileName);
                let fileSize = files.size;
                let maxSizeLimit = window.runtimeConfig.maxFileSize; //in mb
                let fileSizeInMB = fileSize / 1024 / 1024;
                fileSizeArray.push(fileSizeInMB);
                let isValidFileType = validateFileType(fileName);
                if (!isValidFileType) {
                    fileError = true;
                    setShowAlertDialog(true);
                    setAlertDialogMessage("File Type Is Not Supported.");
                    return;
                }
                if (fileSizeInMB > maxSizeLimit) {
                    setShowAlertDialog(true);
                    setAlertDialogMessage(
                        "File size should be less than 100MB"
                    );
                    fileError = true;
                    return;
                }
                if (fileError === true) return;
                const spaceId = activeSpace.id;
                let parentId = parent.id === spaceId ? 0 : parent.id;
                const data = new FormData();
                data.append("file", files, encodeURIComponent(fileName));
                setShowLoader(true);
                http.uploadFile(
                    data,
                    `rest/spacePersistence/file?spaceId=${spaceId}&folderId=${parentId}`,
                    "POST"
                )
                    .then((response) => {
                        if (response.status === 200) {
                            fileNames.forEach((fileName, index) => {
                                let fileExt = fileName.split(".").pop();
                                let fileSize = fileSizeArray[index] + "MB";
                                Mixpanel.callFileManagementEvent(
                                    "Upload",
                                    fileExt,
                                    fileSize
                                );
                            });
                            setShowLoader(false);
                            fetchFolderData(
                                parent.id === spaceId ? { id: 0 } : parent,
                                true
                            );
                        } else {
                            setShowLoader(false);
                        }
                    })
                    .catch((err) => {
                        setShowLoader(false);
                    });
            });
        }
    };
    const handleAddSpaceOrFolderClick = (type) => {
        if (type === "space") {
            setModalTitle("Add New Space");
            setModalType("Space");
            setActionButtonName("Add Space");
        } else {
            setModalTitle("Add New Folder");
            setModalType("Folder");
            setActionButtonName("Add Folder");
        }

        setOpen(true);
    };
    const handleModalButtonAction = (updatedValue) => {
        if (modalType === "Space") {
            addNewSpace(updatedValue);
        } else if (modalType === "Folder") {
            addNewFolder(updatedValue);
        }
    };
    return (
        <>
            <Box sx={{ width: "100%", height: "100%" }}>
                <Paper
                    sx={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }}
                    elevation={3}
                >
                    {isWorkspace &&
                    breadcrumbData.some((item) => item.name === "Flow") ? (
                        <div style={{ margin: "auto" }}>
                            <div className="empty-container">
                                <div>
                                    <img src="./images/bigFlow.svg" alt="sp" />
                                </div>
                                <div className="empty-container-heading">
                                    There are no Flows to Display
                                </div>
                                <div className="empty-container-subheading">
                                    Create Your First Flow to See Them Here
                                </div>
                                <div>
                                    <button
                                        className={"btn-container primary"}
                                        type="button"
                                        id={"viewResults"}
                                        style={{
                                            background: "#044dba",
                                        }}
                                        title="Go to Flow"
                                        value={"Go to Flow"}
                                        onClick={handleFlowChange}
                                    >
                                        Go to Flow
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : isWorkspace ? (
                        <div style={{ margin: "auto" }}>
                            <div className="empty-container">
                                <div>
                                    <img src="./images/ws/space.svg" alt="sp" />
                                </div>
                                <div className="empty-container-heading">
                                    Add New Space
                                </div>
                                <div className="empty-container-subheading">
                                    A space is an area within your workspace
                                    where you can store files and folders
                                </div>
                                <div>
                                    <ButtonComponent
                                        icon={"./images/plus.svg"}
                                        text={"Add Space"}
                                        type={"primary"}
                                        BtnHandler={() =>
                                            handleAddSpaceOrFolderClick("space")
                                        }
                                        disable={disableOperations}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : breadcrumbData.some((item) => item.name === "Flow") &&
                      breadcrumbData.some((item) => item.type === "space") ? (
                        <div style={{ margin: "auto" }}>
                            <div className="empty-container">
                                <div>
                                    <img
                                        src="./images/instance 1 1.svg"
                                        alt="f"
                                    />
                                </div>
                                <div className="empty-container-heading">
                                    No Instances to Display
                                </div>
                                <div className="empty-container-subheading">
                                    Create Flow Instances to See Them Here
                                </div>
                                <div>
                                    <button
                                        className={"btn-container primary"}
                                        type="button"
                                        id={"viewResults"}
                                        style={{
                                            background: "#044dba",
                                        }}
                                        title="Go to Flow"
                                        value={"Go to Flow"}
                                        onClick={handleFlowChange}
                                    >
                                        Go to Flow
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : breadcrumbData.some((item) => item.name === "Emails") &&
                      breadcrumbData.some((item) => item.type === "space") ? (
                        <div style={{ margin: "auto" }}>
                            <div className="empty-container">
                                <div>
                                    <img src="./images/bigEmail.svg" alt="f" />
                                </div>

                                <div className="empty-container-heading">
                                    There are No Emails to Display
                                </div>
                                <div className="empty-container-subheading">
                                    Connect Outlook to Unity Central to see
                                    emails displayed here
                                </div>
                                <div>
                                    <button
                                        className={"btn-container primary"}
                                        type="button"
                                        id={"viewResults"}
                                        style={{
                                            background: "#044dba",
                                        }}
                                        title="Learn How to Connect Outlook"
                                        value={"Learn How to Connect Outlook"}
                                        onClick={""}
                                    >
                                        Learn How to Connect Outlook
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div style={{ margin: "auto" }}>
                            <div className="empty-container">
                                <div>
                                    <img src="./images/ws/Folder.svg" alt="f" />
                                </div>
                                <div className="empty-container-heading">
                                    Upload a new document or add a new folder
                                </div>
                                <div style={{ display: "flex", gap: "8px" }}>
                                    <ButtonComponent
                                        icon={"./images/Add Folder.svg"}
                                        text={"Add folder"}
                                        type={"outlined"}
                                        BtnHandler={() =>
                                            handleAddSpaceOrFolderClick(
                                                "folder"
                                            )
                                        }
                                        disable={disableOperations}
                                    />
                                    <ButtonComponent
                                        icon={"./images/Upload File.svg"}
                                        text={"Upload File"}
                                        type={"primary"}
                                        BtnHandler={() =>
                                            tableFileUplode.current.click()
                                        }
                                        disable={disableOperations}
                                    />
                                    <input
                                        type="file"
                                        id="file"
                                        ref={tableFileUplode}
                                        hidden={true}
                                        onChange={onChangeFileUpload}
                                        onClick={(event) => {
                                            event.currentTarget.value = null;
                                        }}
                                        multiple
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </Paper>
            </Box>
            {open && (
                <WorkspaceModal
                    open={open}
                    setOpen={setOpen}
                    title={modalTitle}
                    type={modalType}
                    existingName={existingName}
                    existingMatcher={existingMatcher}
                    buttonAction={handleModalButtonAction}
                    actionButtonName={actionButtonName}
                    content={content}
                />
            )}
            <Loader show={showLoader} />
            <AlertDialog
                open={showAlertDialog}
                setOpen={setShowAlertDialog}
                message={alertDialogMessage}
                title={""}
                isAlert={false}
            ></AlertDialog>
        </>
    );
};

const mapDispatchToProps = () => {
    return {
        setActivePage,
    };
};

const mapStateToProps = (state) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps())(EmptyTable);
