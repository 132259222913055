const iconList = new Map();
iconList.set("outlook", "./images/outlook-icon.svg");
iconList.set("gmail", "./images/Gmail_icon.png");
iconList.set("radius", "./images/Radius 3.png");
iconList.set("ripple", "./images/Ripple.svg");
iconList.set("alertMenu", "./images/alertMenu.png");

iconList.set("gmail_large", "./images/Gmail_icon_large.svg");

iconList.set("file_small", "./images/shareFile.png");
iconList.set("folder_small", "./images/FOLDER.svg");
iconList.set("ripple_small", "./images/Ripple.svg");
iconList.set("space_small", "./images/Space 3.png");

iconList.set("pdf", "./images/fileTypeIcon/pdf.svg");
iconList.set("txt", "./images/fileTypeIcon/text.svg");
iconList.set("edi", "./images/fileTypeIcon/text.svg");
iconList.set("json", "./images/fileTypeIcon/text.svg");
iconList.set("xlsx", "./images/fileTypeIcon/excel-icon.svg");
iconList.set("xls", "./images/fileTypeIcon/excel-icon.svg");
iconList.set("xlsm", "./images/fileTypeIcon/excel-icon.svg");
iconList.set("pptx", "./images/ppt-icon.svg");
iconList.set("ppt", "./images/ppt-icon.svg");
iconList.set("docx", "./images/fileTypeIcon/DOCX.png");
iconList.set("doc", "./images/fileTypeIcon/doc.svg");
iconList.set("jpg", "./images/fileTypeIcon/jpg-icon.svg");
iconList.set("png", "./images/fileTypeIcon/text.svg");
iconList.set("ai", "./images/fileTypeIcon/text.svg");
iconList.set("mp3", "./images/fileTypeIcon/mp3-icon.svg");
iconList.set("mp4", "./images/fileTypeIcon/mp4-icon.svg");
iconList.set("xml", "./images/fileTypeIcon/xml-file.svg");
iconList.set("html", "./images/fileTypeIcon/htmlIcon.svg");
iconList.set("gif", "./images/fileTypeIcon/gif-icon.svg");
iconList.set("documentChain", "./images/UC-Icons-Color/flow-icon-color.svg");
iconList.set("workspace_small", "./images/workspace-icon-color.svg");

export function getIconPathByType(type) {
    return iconList.get(type);
}
