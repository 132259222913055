import React, { useState } from "react";
import Contact from "./contact";

export default function ContactList({
    contactList,
    handleChatSelection,
    selectedContactIndex,
}) {
    const handleSelectedCard = (chatDetails, chat, contactIndex) => {
        handleChatSelection(chatDetails, chat, contactIndex);
    };
    return (
        <div
            id={"communicatorChatList"}
            style={{
                display: "flex",
                flexDirection: "column",
                cursor: "pointer",
            }}
        >
            {contactList && contactList.length
                ? contactList.map((contact, index) => (
                      <Contact
                          isContactSelected={
                              index === selectedContactIndex ? true : false
                          }
                          contactIndex={index}
                          chat={contact}
                          handleSelectedChat={handleSelectedCard}
                      ></Contact>
                  ))
                : null}
        </div>
    );
}
