import { Divider, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ButtonComponent } from "../../../../button/button";
import http from "../../../../../bae-client/HTTP";
import Loader from "../../../../stylized/Loader";
import { useDispatch } from "react-redux";
import { setSnackbarAction } from "../../../../../services/services/app";

function ControlledSwitches({
    isEnabled,
    isSelectAll,
    index,
    changeFolSelectAll,
    changeForSingleSelect,
    actualIndex,
    stepAlerts = [],
    alert,
}) {
    const [checked, setChecked] = React.useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
        if (isSelectAll) {
            changeFolSelectAll(index, event.target.checked);
            return;
        } else {
            changeForSingleSelect(
                index,
                actualIndex,
                event.target.checked,
                alert?.exceptionConfigurationId
            );
            return;
        }
    };

    useEffect(() => {
        setChecked(isEnabled);
    }, [isEnabled]);

    useEffect(() => {
        if (stepAlerts.length) {
            const isAllEnabled = stepAlerts.every((item) => item.isEnabled);
            setChecked(isAllEnabled);
        }
    }, [stepAlerts]);

    return (
        <Switch
            checked={checked}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
        />
    );
}
const ManageAlerts = ({ toggleDrawer, genericId }) => {
    const [componentData, setComponentData] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [instanceAlrerts, setInstanceAlerts] = useState([]);
    const [stepAlerts, setStepAlerts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();
    const handleSave = async () => {
        try {
            const spreadedData = originalData.flat();

            const changedData = componentData
                .flat()
                .filter(
                    (item, index) =>
                        item.isEnabled !== spreadedData[index].isEnabled
                );
            setIsLoading(true);
            const response = await http.sendRestRequest(
                `documentChain/exception/configuration?genericId=${genericId}`,
                "PUT",
                [...changedData, ...instanceAlrerts]
            );
            setIsLoading(false);
            dispatch(
                setSnackbarAction({
                    open: true,
                    message: "Changes saved!",
                    severity: "success",
                })
            );
            fetchAlertData();
            // toggleDrawer(false)({});
        } catch (error) {
            dispatch(
                setSnackbarAction({
                    open: true,
                    message:
                        error?.error ||
                        error?.message ||
                        "Something went wrong, please try again!",
                    severity: "error",
                })
            );
        } finally {
            setIsLoading(false);
        }
    };

    const fetchAlertData = async () => {
        try {
            setIsLoading(true);
            const response = await http.sendRestRequest(
                `documentChain/exception/configuration/list?genericId=${genericId}`
            );
            const instanceAlert = response?.shift();
            setInstanceAlerts([instanceAlert]);
            setStepAlerts(response);
            const alertObject = Object.groupBy(
                response,
                ({ stepName }) => stepName
            );
            const responseData = Object.values(alertObject);
            setComponentData(responseData);
            setOriginalData(responseData);
        } catch (error) {
            dispatch(
                setSnackbarAction({
                    open: true,
                    message:
                        error?.error ||
                        error?.message ||
                        "Something went wrong, please try again!",
                    severity: "error",
                })
            );
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchAlertData();
    }, []);
    const changeFolSelectAll = (index, checked) => {
        setComponentData((prev) => {
            const latestData = prev.map((d, i) => {
                if (i === index) {
                    setStepAlerts((prev) => {
                        const latestData = prev.map((step) => {
                            if (
                                step.exceptionConfigurationId ===
                                d.exceptionConfigurationId
                            ) {
                                return { ...step, isEnabled: checked };
                            }
                            return step;
                        });
                        return latestData;
                    });
                    return d.map((item) => {
                        return { ...item, isEnabled: checked };
                    });
                }
                return d;
            });
            return latestData;
        });

        const forStep = componentData[index];

        setStepAlerts((prev) => {
            const latestData = prev.map((d) => {
                if (d.stepName === forStep[0].stepName)
                    return { ...d, isEnabled: checked };
                return d;
            });
            return latestData;
        });
    };

    const changeForSingleSelect = (index, actualIndex, checked, id) => {
        setComponentData((prev) => {
            const latestData = prev.map((d, i) => {
                if (i === index) {
                    return d.map((item, index) => {
                        if (index === actualIndex) {
                            return { ...item, isEnabled: checked };
                        }
                        return item;
                    });
                }
                return d;
            });
            return latestData;
        });
        setStepAlerts((prev) => {
            const latestData = prev.map((d, i) => {
                if (d.exceptionConfigurationId === id) {
                    return { ...d, isEnabled: checked };
                }
                return d;
            });
            return latestData;
        });
    };

    const instanceChangeForSingleSelect = (index, checked) => {
        setInstanceAlerts((prev) => {
            const latestData = prev.map((d, i) => {
                if (i === index) {
                    return { ...d, isEnabled: checked };
                }
                return d;
            });
            return latestData;
        });
    };
    const stepChangeForSelectAll = (index, checked) => {
        setComponentData((prev) => {
            const latestData = prev.map((d, i) => {
                return d.map((item) => {
                    return { ...item, isEnabled: checked };
                });
            });
            return latestData;
        });
        setStepAlerts((prev) => {
            const latestData = prev.map((d) => {
                return { ...d, isEnabled: checked };
            });
            return latestData;
        });
    };

    const handleSelectAll = (type, index, checked) => {
        setComponentData((prev) => {
            const latestData = prev.map((d, i) => {
                return d.map((item) => {
                    if (item.exceptionType === type) {
                        return { ...item, isEnabled: checked };
                    }
                    return item;
                });
            });
            return latestData;
        });

        setStepAlerts((prev) => {
            const latestData = prev.map((d) => {
                if (d.exceptionType === type) {
                    return { ...d, isEnabled: checked };
                }
                return d;
            });
            return latestData;
        });
    };
    return (
        <div className="manage-alerts">
            <div className="manage-alerts-heading">
                <div className="manage-alerts-heading-text">Manage Alerts</div>
                <div
                    style={{ cursor: "pointer" }}
                    onClick={toggleDrawer(false)}
                >
                    &#10006;
                </div>
            </div>
            <div className="instance-alert">
                <div className="alert-card">
                    <div className="text ">{"Instance Alert"}</div>
                    <div className="table">
                        <div className="alert-row">
                            <div
                                className="alert-row-text"
                                style={{ fontWeight: "bold" }}
                            >
                                {"Alert"}
                            </div>
                            <div
                                className="alert-row-text"
                                style={{ fontWeight: "bold" }}
                            >
                                {"Enable"}
                            </div>
                        </div>
                        <div className="alert-row">
                            <div className="alert-row-text">
                                {"PROACTIVE_ALERT"}
                            </div>
                            <div className="alert-row-text">
                                <ControlledSwitches
                                    isEnabled={instanceAlrerts[0]?.isEnabled}
                                    changeFolSelectAll={
                                        instanceChangeForSingleSelect
                                    }
                                    stepAlerts={instanceAlrerts}
                                    index={0}
                                    isSelectAll={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        width: "100%",
                        borderBottom: "2px solid #D7DEEA",
                    }}
                ></div>
                <div className="alert-card">
                    <div className="text ">{"All Steps"}</div>
                    <div className="table">
                        <div className="alert-row">
                            <div
                                className="alert-row-text"
                                style={{ fontWeight: "bold" }}
                            >
                                {"Alert"}
                            </div>
                            <div
                                className="alert-row-text"
                                style={{ fontWeight: "bold" }}
                            >
                                {"Enable"}
                            </div>
                        </div>
                        <div className="alert-row">
                            <div className="alert-row-text">{"Select All"}</div>
                            <div className="alert-row-text">
                                <ControlledSwitches
                                    isEnabled={false}
                                    isSelectAll={true}
                                    index={0}
                                    changeFolSelectAll={stepChangeForSelectAll}
                                    stepAlerts={stepAlerts}
                                />
                            </div>
                        </div>
                        <div className="alert-row">
                            <div className="alert-row-text">{"DELAYED"}</div>
                            <div className="alert-row-text">
                                <ControlledSwitches
                                    isEnabled={false}
                                    isSelectAll={true}
                                    index={0}
                                    changeFolSelectAll={(index, checked) =>
                                        handleSelectAll(
                                            "DELAYED",
                                            index,
                                            checked
                                        )
                                    }
                                    stepAlerts={stepAlerts.filter(
                                        (d) => d.exceptionType === "DELAYED"
                                    )}
                                />
                            </div>
                        </div>
                        <div className="alert-row">
                            <div className="alert-row-text">{"SKIPPED"}</div>
                            <div className="alert-row-text">
                                <ControlledSwitches
                                    isEnabled={false}
                                    isSelectAll={true}
                                    index={0}
                                    changeFolSelectAll={(index, checked) =>
                                        handleSelectAll(
                                            "SKIPPED",
                                            index,
                                            checked
                                        )
                                    }
                                    stepAlerts={stepAlerts.filter(
                                        (d) => d.exceptionType === "SKIPPED"
                                    )}
                                />
                            </div>
                        </div>
                        <div className="alert-row">
                            <div className="alert-row-text">
                                {"PROACTIVE_ALERT"}
                            </div>
                            <div className="alert-row-text">
                                <ControlledSwitches
                                    isEnabled={false}
                                    isSelectAll={true}
                                    index={0}
                                    changeFolSelectAll={(index, checked) =>
                                        handleSelectAll(
                                            "PROACTIVE_ALERT",
                                            index,
                                            checked
                                        )
                                    }
                                    stepAlerts={stepAlerts.filter(
                                        (d) =>
                                            d.exceptionType ===
                                            "PROACTIVE_ALERT"
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        width: "100%",
                        borderBottom: "2px solid #D7DEEA",
                    }}
                ></div>
            </div>
            <div className="alert-cards invisibleScroller">
                {!componentData.length
                    ? null
                    : componentData.map((data, index) => (
                          <React.Fragment>
                              <div
                                  className="alert-card"
                                  key={data[0].stepName}
                              >
                                  <div className="text">{data[0].stepName}</div>
                                  <div className="table">
                                      <div className="alert-row">
                                          <div
                                              className="alert-row-text"
                                              style={{ fontWeight: "bold" }}
                                          >
                                              {"Alert"}
                                          </div>
                                          <div
                                              className="alert-row-text"
                                              style={{ fontWeight: "bold" }}
                                          >
                                              {"Enable"}
                                          </div>
                                      </div>
                                      <div className="alert-row">
                                          <div className="alert-row-text">
                                              {"Select All"}
                                          </div>
                                          <div className="alert-row-text">
                                              <ControlledSwitches
                                                  isEnabled={false}
                                                  isSelectAll={true}
                                                  index={index}
                                                  changeFolSelectAll={
                                                      changeFolSelectAll
                                                  }
                                                  stepAlerts={data}
                                              />
                                          </div>
                                      </div>
                                      {data.map((item, actualIndex) => (
                                          <div className="alert-row">
                                              <div className="alert-row-text">
                                                  {item.exceptionType}
                                              </div>
                                              <div className="alert-row-text">
                                                  <ControlledSwitches
                                                      isEnabled={item.isEnabled}
                                                      changeForSingleSelect={
                                                          changeForSingleSelect
                                                      }
                                                      index={index}
                                                      actualIndex={actualIndex}
                                                      alert={item}
                                                  />
                                              </div>
                                          </div>
                                      ))}
                                  </div>
                              </div>
                              <div
                                  style={{
                                      width: "100%",
                                      borderBottom: "2px solid #D7DEEA",
                                  }}
                              ></div>
                          </React.Fragment>
                      ))}
            </div>

            <div className="manage-alert-action">
                <ButtonComponent
                    text={"Cancel"}
                    BtnHandler={toggleDrawer(false)}
                    type={"outlined"}
                />
                <ButtonComponent
                    text={"Confirm"}
                    BtnHandler={handleSave}
                    type={"primary"}
                />
            </div>
            <Loader show={isLoading} />
        </div>
    );
};

export default ManageAlerts;
