import http from "../bae-client/HTTP";
export const fetchDocumentChainList = () => {
    return async (dispatch) => {
        const response = await http.sendRestRequest(
            "documentChain/generic/list"
        );
        dispatch({
            type: "LOAD_DOCUMENT_CHAIN_LIST",
            payload: response,
        });
    };
};
export const setSelectedFlows = (payload = []) => {
    return {
        type: "SET_FLOWS",
        payload: payload,
    };
};
export const setSelectedFlowIds = (payload = []) => {
    return {
        type: "SET_FLOW_IDS",
        payload: payload,
    };
};
export const setFinalFilter = (payload = []) => {
    return {
        type: "SET_FINAL_FILTER",
        payload: payload,
    };
};
export const setSelectedAlerts = (payload = []) => {
    return {
        type: "SET_ALERT_IDS",
        payload: payload,
    };
};

export const setSelectedFlowIdsAlert = (payload = []) => {
    return {
        type: "SET_FLOW_IDS_ALERT",
        payload: payload,
    };
};
export const setSelectedFilterOptions = (payload = []) => {
    return {
        type: "SET_FILTER_OPTIONS_ALERT",
        payload: payload,
    };
};
export const setSelectedExceptionIds = (payload = []) => {
    return {
        type: "SET_EXCEPTION_IDS",
        payload: payload,
    };
};
export const setSelectedExceptionAlertIds = (payload = []) => {
    return {
        type: "SET_EXCEPTION_ALERT_IDS",
        payload: payload,
    };
};

export const setSelectedTimeFilterGlobal = (payload = []) => {
    return {
        type: "SET_TIME_FILTER",
        payload: payload,
    };
};
export const setSelectedTimeFilterGlobalAlert = (payload = []) => {
    return {
        type: "SET_TIME_FILTER_ALERT",
        payload: payload,
    };
};
export const setSelectedExceptions = (payload = []) => {
    return {
        type: "SET_EXCEPTIONS",
        payload: payload,
    };
};

export const setAllInstanceWithFilter = (payload = []) => {
    return {
        type: "SET_ALL_INSTANCES",
        payload: payload,
    };
};

export const setAllInstanceWithFilterAlert = (payload = []) => {
    return {
        type: "SET_ALL_INSTANCES_ALERT",
        payload: payload,
    };
};
