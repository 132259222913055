import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

export default function AlertDialog({
    open,
    setOpen,
    title,
    message,
    isAlert,
    handleConfirmationDialog,
    messageId,
    buttonId,
    buttonName,
}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const handleClose = (event) => {
        setOpen(false);
        if (handleConfirmationDialog) {
            event.target.innerText.trim() === "ARCHIVE" ||
            event.target.innerText.trim() === "DELETE" ||
            event.target.innerText.trim() === "ADD" ||
            event.target.innerText.trim() === "ADD CONTACT"
                ? handleConfirmationDialog("YES", event.target.innerText.trim())
                : handleConfirmationDialog("NO", "CANCEL");
        }
    };
    return open ? (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <div className="row m-0 w-100">
                    <div className="col-11">
                        <DialogTitle
                            id="responsive-dialog-title"
                            style={{ paddingLeft: "9px !important" }}
                        >
                            {title}
                        </DialogTitle>
                    </div>
                    <div className="col-1 p-0 doCenter">
                        <button
                            style={{ marginRight: "18px" }}
                            type="button"
                            onClick={handleClose}
                            className="close modalCloseIcon"
                            data-dismiss="modal"
                            id={"closeModalButton"}
                        >
                            &times;
                        </button>
                    </div>
                </div>
                <DialogContent>
                    <DialogContentText
                        className="archive-message"
                        id={messageId ? messageId : "errorMessageText"}
                    >
                        {message}
                    </DialogContentText>
                </DialogContent>
                {isAlert ? (
                    <DialogActions className="archeive-btn">
                        <Button
                            id="button_modal_cancel"
                            onClick={handleClose}
                            style={{
                                background: "#FFFFFF",
                                borderRadius: "10px",
                                border: " 1px solid #000000",
                            }}
                        >
                            {"Cancel"}
                        </Button>
                        <Button
                            onClick={handleClose}
                            autoFocus
                            style={{
                                background: "#0798EA",
                                borderRadius: "10px",
                                color: "white",
                            }}
                            id={buttonId}
                        >
                            {buttonName || "OK"}
                        </Button>
                    </DialogActions>
                ) : (
                    <DialogActions>
                        <Button
                            autoFocus
                            onClick={handleClose}
                            color="primary"
                            id={buttonId}
                        >
                            {"Close"}
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
        </div>
    ) : null;
}
