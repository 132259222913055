import CuboidManager from "./CuboidManager";
import http from "./HTTP";
class AlertManager {
    constructor(options) {
        if (options !== undefined) {
            this.configData = options;
            this.alertCollabId = options.alertCollabId;
            this.alertWBId = options.alertWBId;
            this.alertConfigCuboidID = options.alertConfigId;
        }
        //this.cuboidManager = new CuboidManager(this.alertCollabId, this.alertWBId);
        this.alertId = null;
        this.alertTitle = null;
        this.alertContent = null;
        this.alertSize = null;
        this.alertManagerData = null;
        this.HomeAlerts = {};
        this.allAlerts = null;
        this.workspaceIdToAlerts = {};
        this.alertRowIdToIndex = {};
        this.archivedAlerts = [];
    }
    getAlertManagerData() {
        return this.alertManagerData;
    }
    setAlertManagerData(data) {
        this.alertManagerData = data;
    }
    getAlertConfigData = () => {
        return this.configData;
    };
    setData(options) {
        if (options.alertId !== undefined && options.alertId !== null) {
            this.alertId = options.alertId;
        }
        if (options.alertTitle !== undefined && options.alertTitle !== null) {
            this.alertTitle = options.alertTitle;
        }
        if (
            options.alertContent !== undefined &&
            options.alertContent !== null
        ) {
            this.alertContent = options.alertContent;
        }
        if (options.alertSize !== undefined && options.alertSize !== null) {
            this.alertSize = options.alertSize;
        }
    }
    generateAlertCongiRow = (data) => {
        let rows = {
            rows: [data],
        };
        return rows;
    };
    insertNewAlert = (data) => {
        return new Promise((resolve, reject) => {
            let url = "alert";
            http.sendRestRequest(url, "POST", data)
                .then((resp) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
    loadAllAlerts = () => {
        let existingAlerts = [];
        this.archivedAlerts = [];
        this.alertManagerData.forEach((rowItem, index) => {
            let archiveStatus = -1;
            archiveStatus = rowItem.archive ? rowItem.archive : 0;
            let alertWidget = {
                archive: rowItem.archive,
                rowId: rowItem.alertRegistryId,
                alertTitle: rowItem.alertName,
                workspaceId: rowItem.workspaceId,
                alertType: rowItem.alertType,
                // homeAlert: rowItem.attachToHome,
                alertBody: rowItem.alertBody,
                clickAction: rowItem.clickAction,
                clickActionParameters: rowItem.clickActionParameters,
                updatedOn: rowItem.updatedOn,
            };
            this.alertRowIdToIndex[rowItem.alertRegistryId] = index;
            if (archiveStatus === 0) {
                this.HomeAlerts = alertWidget;
            }
            if (archiveStatus === 0) {
                existingAlerts.push(alertWidget);
            } else if (archiveStatus === 1) {
                let obj = {
                    archive: rowItem.archive,
                    rowId: rowItem.alertRegistryId,
                    Name: rowItem.alertName,
                    workspaceId: rowItem.workspaceId,
                    alertType: rowItem.alertType,
                    // homeAlert: rowItem.attachToHome,
                    alertBody: rowItem.alertBody,
                    updatedOn: rowItem.updatedOn,
                    clickAction: rowItem.clickAction,
                    clickActionParameters: rowItem.clickActionParameters,
                    archivedDate: 44301,
                    type: "alert",
                };
                this.archivedAlerts.push(obj);
            }
        });
        if (existingAlerts.length !== 0) {
            this.allAlerts = existingAlerts;
            return existingAlerts;
        }
    };
    insertNewAlertByObject = (obj) => {
        this.allAlerts.push(obj); //insert new alert in allAlert Array
    };
    updateAlertByRowId = (rowId, obj) => {
        this.allAlerts.forEach((item, index) => {
            if (item.rowId === rowId) {
                this.allAlerts[index] = obj;
            }
        });
        return this.allAlerts;
    };
    archiveNewAlert = (rowId, alertObj) => {
        this.allAlerts.forEach((item, index) => {
            if (item.rowId === rowId) {
                this.allAlerts.splice(index, 1);
                this.archivedAlerts.push(alertObj);
            }
        });
        return this.allAlerts;
    };
    restoreAlert = (rowId, alertObj) => {
        this.archivedAlerts.forEach((item, index) => {
            if (item.rowId === rowId) {
                this.allAlerts.push(alertObj);
                this.archivedAlerts.splice(index, 1);
            }
        });
        return this.archivedAlerts;
    };
    parseAlertRawObject = (responseObject) => {
        return {
            archive: responseObject.archive,
            rowId: responseObject.alertRegistryId,
            alertTitle: responseObject.alertame,
            workspaceId: responseObject.workspaceId,
            alertType: responseObject.alertType,
            // homeAlert: responseObject.attachToHome,
            alertBody: responseObject.alertBody,
            clickAction: responseObject.clickAction,
            clickActionParameters: responseObject.clickActionParameters,
            updatedOn: responseObject.updatedOn,
        };
    };
    parseArchiveAlertObj = (responseObject) => {
        return {
            archive: responseObject.archive,
            rowId: responseObject.alertRegistryId,
            Name: responseObject.alertName,
            workspaceId: responseObject.workspaceId,
            alertType: responseObject.alertType,
            // homeAlert: responseObject.attachToHome,
            alertBody: responseObject.alertBody,
            clickAction: responseObject.clickAction,
            clickActionParameters: responseObject.clickActionParameters,
            updatedOn: responseObject.updatedOn,
            archivedDate: 44301,
            type: "alert",
        };
    };
    generateIndexes = () => {
        this.allAlerts.forEach((rowItem, index) => {
            this.alertRowIdToIndex[rowItem.rowId] = index;
        });
    };
    getHomeAlerts = () => {
        return this.HomeAlerts;
    };
    getAllAlerts = () => {
        return this.allAlerts;
    };
    getAlertRowIndexByRowId = (rowId) => {
        return this.alertRowIdToIndex[rowId];
    };
    getAlertByRowIndex = (index) => {
        return this.alertManagerData[index];
    };
    generateRowForUpdate = (data) => {
        let rows = {
            rows: [data],
        };
        return rows;
    };
    addAlertToHome = (status, rowSeqNum) => {
        return new Promise((resolve, reject) => {
            let data = [status];
            let rowSeqNumber = rowSeqNum;
            let columnSequenceNo = 4;
            let row = this.generateRowForUpdate(data);
            this.cuboidManager.updatecell(row, rowSeqNumber, columnSequenceNo);
            this.cuboidManager.submit().then((resp) => {
                resolve(resp);
            });
        });
    };
    updateAlertRow = (rowData, rowId) => {
        let promise = new Promise((resolve, reject) => {
            let data = rowData;
            let rowSeqNumber = this.getAlertRowIndexByRowId(rowId);
            let row = this.generateRowForUpdate(rowData);
            this.cuboidManager.updateRowByIndex(row, rowSeqNumber);
            this.cuboidManager.submit().then((resp) => {
                resolve(resp);
            });
        });
        return promise;
    };
    loadAlertRegistryData = () => {
        let url = "alert/list";
        http.sendRestRequest(url, "GET").then((response) => {
            this.alertManagerData = response;
        });
    };
    insertNewAlertRowByAPI = (rowObj) => {
        let promise = new Promise((resolve, reject) => {
            let url = "alert";
            let dataObj = rowObj;
            http.sendRestRequest(url, "POST", dataObj)
                .then((response) => {
                    resolve(response);
                })
                .catch((errorResponse) => {
                    reject(errorResponse);
                    console.log(errorResponse);
                });
        });
        return promise;
    };

    updateAlertRowByAPI = (rowObj) => {
        let promise = new Promise((resolve, reject) => {
            let url = "alert";
            let dataObj = rowObj;
            http.sendRestRequest(url, "PUT", dataObj)
                .then((response) => {
                    // this.alertManagerData = response;
                    resolve(response);
                })
                .catch((errorResponse) => {
                    reject(errorResponse);
                    console.log(errorResponse);
                });
        });
        return promise;
    };
    deleteAlertRowByAPI = (rowObj) => {
        let promise = new Promise((resolve, reject) => {
            let url = "alert";
            http.sendRestRequest(url, "DELETE", rowObj)
                .then((response) => {
                    // this.alertManagerData = response;
                    resolve(response);
                })
                .catch((errorResponse) => {
                    reject(errorResponse);
                    console.log(errorResponse);
                });
        });
        return promise;
    };
    loadDataByAPI = () => {
        let promise = new Promise((resolve, reject) => {
            let url = "alert/list";
            http.sendRestRequest(url, "GET")
                .then((response) => {
                    this.alertManagerData = response;
                    resolve(response);
                })
                .catch((errorResponse) => {
                    reject(errorResponse);
                    console.log(errorResponse);
                });
        });
        return promise;
    };
    loadAlertConfig() {
        let promise = new Promise((resolve, reject) => {
            this.loadDataByAPI()
                .then((response) => {
                    this.alertManagerData = response;
                    resolve(response);
                })
                .catch((errorResponse) => {
                    reject(errorResponse);
                    console.log(errorResponse);
                });
        });

        return promise;
    }
}
export default AlertManager;
