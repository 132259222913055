import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import http from "../../../../../../bae-client/HTTP";
import "./style.css";
import Chip from "@mui/material/Chip";
import AddStep from "../modals/AddStep";
import DialogView from "../../../../../stylized/DialogView";
import AlertDialog from "../../../../../stylized/AlertDialog";
import Loader from "../../../../../stylized/Loader";
import CustomSnackbar from "../../../../../stylized/CustomSnackbar/CustomSnackbar";

import { useSelector } from "react-redux";
import AddStepStepper from "../modals/AddStepStepper";
import { fetchFilterData } from "../../../../../../actions/DashboardActions";
import { setSnackbarAction } from "../../../../../../services/services/app";
import { useDrag, useDrop } from "react-dnd";

const GenericStep = ({
    isMergeModal,
    step,
    moveStep,
    editGeneric,
    index,
    handleSelectAll,
    handleInputRadioClick,
    isSelected,
    handleCheckboxClick,
    setEditGeneric,
    editStep,
    genericSteps,
    selectedRow,
    setSelectedGenericSteps,
    stepIndex,
}) => {
    const dispatch = useDispatch();
    const isReadOnlyUser = sessionStorage.getItem("isReadOnlyUser") === "true";
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showLoader, setShowLoader] = React.useState(false);

    const [showSnackbar, setShowSnackbar] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState("");
    const [alertDialogShow, setAlertDialogShow] = React.useState(false);
    const [alertDialogContent, setAlertDialogContent] = React.useState({
        title: "",
        message: "",
        isAlert: false,
        messageId: "",
        buttonId: "",
    });
    const showAlertView = (title, body, messageId, buttonId, isError) => {
        setAlertDialogContent({
            title: title,
            message: body,
            messageId: messageId,
            buttonId: buttonId,
        });
        isError ? setAlertDialogShow(true) : setShowSnackbar(true);
    };

    const [errorAlert, setErrorAlert] = React.useState({
        showAlertView: false,
        isAlert: false,
        alertDialogTitle: "",
        alertDialogMessage: "",
    });
    const [showEditStepModal, setShowEditStepModal] = useState(false);
    const [displayShow, setDisplayShow] = useState(false);
    const [rawDataToEdit, setRawDataToEdit] = useState(null);
    const open = Boolean(anchorEl);
    const [{ isDragging }, drag] = useDrag({
        type: "ItemType",
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        canDrag: () => editGeneric,
    });

    const [, drop] = useDrop({
        accept: "ItemType",
        drop: (draggedItem) => {
            if (draggedItem.index !== index && editGeneric) {
                moveStep(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
    });
    const handleClick = (event, row) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (e) => {
        setAnchorEl(null);
    };
    const deleteClickHandler = (e) => {
        handleClose();
        let independent = false;
        for (let i = stepIndex + 1; i < genericSteps.length; i++) {
            if (
                genericSteps[i].expectedDurationFrom === step.genericStepId &&
                genericSteps[i].expectedDuration > 0
            ) {
                independent = true;
            }
            if (independent === true) {
                setAlertDialogContent({
                    title: "Operation Failed",
                    message:
                        "There are few other steps dependent on this step. Please remove the dependency and then delete it",
                });
                setAlertDialogShow(true);
                return;
            }
        }
        setAlertDialogContent({
            title: "Delete Generic Step",
            message:
                "Are you sure you want to Delete the selected Generic Step?",
            isAlert: true,
            buttonName: "Delete",
            buttonId: "btn_delete_generic_step",
        });
        setAlertDialogShow(true);
    };
    const fetchLatestFlowsData = () => {
        http.sendRestRequest("documentChain/objects")
            .then((response) => {
                dispatch(fetchFilterData(response));
            })
            .catch((err) => console.log(err));
    };
    const deleteItems = (id) => {
        if (id > 0) {
            return new Promise((resolve, reject) => {
                let url = `documentChain/generic/step?genericId=${selectedRow.genericId}&genericStepId=${step.genericStepId}`;

                http.sendRestRequest(url, "DELETE", id)
                    .then((resp) => {
                        fetchLatestFlowsData();
                        resolve(resp);
                        http.sendRestRequest(
                            `documentChain/generic/stepList?genericId=${selectedRow.genericId}`
                        )
                            .then((genericData) => {
                                if (genericData.length > 0) {
                                    setSelectedGenericSteps(genericData);
                                    handleClose();
                                } else {
                                    setSelectedGenericSteps(genericData);
                                    handleClose();
                                    setEditGeneric(true);
                                }
                            })
                            .catch((error) => {
                                setShowLoader(false);
                                setAlertDialogShow(true);
                                setAlertDialogContent(error.error);
                            });

                        setShowSnackbar(false);
                        setSelectedGenericSteps([]);
                        handleClose();
                    })
                    .catch((err) => {
                        setShowLoader(false);
                        reject(err);
                        setAlertDialogShow(true);
                        setAlertDialogContent(err.error);
                        console.log(err);
                    });
            });
        }
    };
    const handleConfirmationDialog = (response, isDelete) => {
        setAlertDialogContent({
            title: "",
            message: "",
            isAlert: false,
        });
        if (response === "YES") {
            setShowLoader(true);
            if (isDelete === "DELETE") {
                const body = step.genericStepId;
                deleteItems(body)
                    .then((resp) => {
                        setShowLoader(false);
                        if (resp === 200) {
                            setSnackbarMessage(
                                "Generic Step Deleted successfully!"
                            );

                            setShowSnackbar(true);
                            dispatch(
                                setSnackbarAction({
                                    open: true,
                                    message: `Generic Step Deleted successfully!`,
                                    severity: "success",
                                })
                            );
                        } else {
                            setShowLoader(false);
                            showAlertView(
                                "Delete Item",
                                "Something went wrong, Please try again!"
                            );
                        }
                    })
                    .catch((err) => {
                        showAlertView(
                            "Operation Failed",
                            err.error,
                            "",
                            "",
                            true
                        );
                        setShowLoader(false);
                    });
            }
        }
    };
    const closeEditModal = () => {
        setShowEditStepModal(false);
        setRawDataToEdit(null);
    };
    const getDurationForStep = (mins, prevStepId) => {
        const min = mins;
        const hours = Math.floor(mins / 60);
        const days = Math.floor(hours / 24);
        let prevStep = "";
        genericSteps.forEach((step) => {
            if (step.genericStepId === prevStepId) {
                prevStep = step.genericStepName;
            }
        });

        if (days > 0) {
            return `${days} days after ${prevStep}`;
        } else if (hours > 0) {
            return `${hours} hours after ${prevStep}`;
        } else return `${min} minutes after ${prevStep}`;
    };
    const editStepModal = () => {
        handleClose();
        setRawDataToEdit(step);
        setShowEditStepModal(true);
    };
    const editExistingStep = (data, isInstanceStep, noChange) => {
        setShowEditStepModal(false);
        data.genericStepId = step.genericStepId;
        editStep(data, isInstanceStep, noChange);
        setRawDataToEdit(null);
    };
    return (
        <>
            <div
                className="stepContainer"
                ref={(node) => drag(drop(node))}
                style={{
                    opacity: isDragging ? 0.5 : 1,
                    padding: "16px",
                    marginBottom: "8px",
                    // backgroundColor: "#fff",
                    cursor: isDragging ? "move" : "pointer",
                }}
            >
                {isMergeModal ? (
                    <div>
                        <input
                            type="checkbox"
                            checked={isSelected}
                            onChange={handleCheckboxClick}
                        />
                    </div>
                ) : (
                    ""
                )}
                <div
                    className="contentRow"
                    style={{
                        cursor: isReadOnlyUser ? "not-allowed" : "pointer",
                        marginBottom: "5px",
                    }}
                >
                    <div
                        className="content textEllipsis"
                        title={step.genericStepName}
                    >
                        {step.genericStepName}
                    </div>
                    {isMergeModal ? null : (
                        <div
                            id={"div_generic_option_" + step.genericStepId}
                            className={isReadOnlyUser ? "readOnly" : ""}
                            onClick={(event) =>
                                isReadOnlyUser ? null : handleClick(event)
                            }
                            style={{
                                cursor: "pointer",
                                height: "24px",
                            }}
                        >
                            <img
                                alt={"options"}
                                title="options"
                                src="./images/options.png"
                            ></img>
                        </div>
                    )}
                </div>
                <div className="contentRow">
                    <div
                        className="content textEllipsis"
                        style={{
                            width: "33%",
                            cursor: isReadOnlyUser ? "not-allowed" : "pointer",
                        }}
                        title={step.fromEntity}
                    >
                        From: {step.fromEntity}
                    </div>
                    <div
                        className="content textEllipsis"
                        style={{
                            width: "33%",
                            cursor: isReadOnlyUser ? "not-allowed" : "pointer",
                        }}
                        title={step.toEntity}
                    >
                        To: {step.toEntity}
                    </div>
                    <div
                        className="content textEllipsis"
                        style={{
                            width: "33%",
                            cursor: isReadOnlyUser ? "not-allowed" : "pointer",
                        }}
                        title={
                            stepIndex !== 0 &&
                            getDurationForStep(
                                step.expectedDuration,
                                step.expectedDurationFrom
                            )
                        }
                    >
                        {stepIndex !== 0 && step.expectedDuration !== 0
                            ? getDurationForStep(
                                  step.expectedDuration,
                                  step.expectedDurationFrom
                              )
                            : null}
                    </div>
                </div>
                {isMergeModal ? null : (
                    <>
                        <div className="contentRow">
                            <div
                                className="content textEllipsis"
                                style={{
                                    width: "80%",
                                    cursor: isReadOnlyUser
                                        ? "not-allowed"
                                        : "pointer",
                                }}
                                title={step.storageLocation}
                            >
                                Location : {step.storageLocation}
                            </div>
                        </div>
                        <div className="contentRow">
                            <div
                                className="content textEllipsis"
                                style={{
                                    cursor: isReadOnlyUser
                                        ? "not-allowed"
                                        : "pointer",
                                }}
                                title={step.promiseType}
                            >
                                Promise Type: {step.promiseType}
                            </div>

                            {step.blockType === "Optional" ? (
                                <div className="content contentChip textEllipsis">
                                    <Chip
                                        label={"OPTIONAL"}
                                        style={{
                                            color: "black",
                                            background: "white",
                                            border: "1px solid black",
                                            width: "100px",
                                            height: "30px",
                                        }}
                                        size="medium"
                                    ></Chip>
                                </div>
                            ) : null}
                        </div>
                    </>
                )}
            </div>
            <Menu
                id="demo-customized-menu"
                MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={editStepModal}>
                    <div id="div_edit_generic">
                        <img
                            className="renameIcon"
                            src="./images/Rename.svg"
                            alt="Edit"
                            title="Edit"
                            style={{ width: "18px", height: "18px" }}
                        />
                        <span className="btn_title" title="Edit">
                            Edit
                        </span>
                    </div>
                </MenuItem>
                <MenuItem onClick={deleteClickHandler}>
                    <div id="div_delete_generic">
                        <img
                            className="deleteIcon"
                            src="./images/delete.svg"
                            alt="Delete"
                            title="Delete"
                            style={{ width: "18px", height: "18px" }}
                        />
                        <span className="btn_title" title="Delete">
                            Delete
                        </span>
                    </div>
                </MenuItem>
                <MenuItem disabled onClick={handleClose}>
                    <div>
                        <img
                            className="renameIcon"
                            src="./images/clone.svg"
                            alt="Edit"
                            title="Clone"
                            style={{ width: "18px", height: "18px" }}
                        />
                        <span className="btn_title" title="Clone">
                            Clone
                        </span>
                    </div>
                </MenuItem>
            </Menu>
            {/* <AddStep
                showAddDocumentChainModal={showEditStepModal}
                closeModal={closeEditModal}
                addNewStep={editExistingStep}
                title={"Edit Step"}
                btnName={"Update"}
                genericStepList={genericSteps}
                editStepData={rawDataToEdit}
            ></AddStep> */}
            <DialogView
                show={showEditStepModal}
                size="lg"
                handleClose={setShowEditStepModal}
                showTitle={false}
                showFooter={false}
                onBackdropClick={false}
                style={displayShow ? { display: "none" } : { display: "block" }}
            >
                <AddStepStepper
                    editStepData={rawDataToEdit}
                    onHide={setShowEditStepModal}
                    hideFile={"true"}
                    Display={setDisplayShow}
                    saveButtonName={"NEXT"}
                    genericStepList={genericSteps}
                    title={"Edit Step"}
                    curruntAction={"edit"}
                    addNewStep={editExistingStep}
                    parentStepId={
                        rawDataToEdit &&
                        genericSteps[rawDataToEdit.genericOrderId - 2] &&
                        genericSteps[rawDataToEdit.genericOrderId - 2]
                            .genericStepId
                    }
                    genericSteps={genericSteps}
                ></AddStepStepper>
            </DialogView>
            <Loader show={showLoader}></Loader>
            <CustomSnackbar
                open={showSnackbar}
                setOpen={setShowSnackbar}
                alertMessage={snackbarMessage}
            ></CustomSnackbar>
            <AlertDialog
                open={alertDialogShow}
                setOpen={setAlertDialogShow}
                title={alertDialogContent.title}
                handleConfirmationDialog={handleConfirmationDialog}
                message={alertDialogContent.message}
                isAlert={alertDialogContent.isAlert}
                messageId={alertDialogContent.messageId}
                buttonId={alertDialogContent.buttonId}
                buttonName={alertDialogContent.buttonName}
            ></AlertDialog>
        </>
    );
};

export default GenericStep;
