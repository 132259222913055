import http from "../bae-client/HTTP";

export const fetchUnreadCountOfAlerts = () => {
    return async (dispatch) => {
        const response = await http.sendRestRequest(
            "alert/unread/counts"
        );
        dispatch({
            type: "FETCH_UNREAD_COUNT_OF_ALERTS",
            payload: response,
        });
    };
};